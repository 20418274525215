import {AbiFormFunction} from './abiform';
import AbiInput from './abiInputForm';

async function init () {
    const extensions = {
        label: 'abi-form',
        items: [
            {
                label: '',
                value: (props) => <AbiFormFunction {...props}/>,
            },
            {
                label: 'abi',
                value: (props) => <AbiInput {...props}/>,
            }
        ]
    }
    return {extensions};
}

export default init;
