export function splitPresentation (mdcontent) {
    const splitter = /(-{3,})\n/g;
    const start = `:::section[]{data-markdown="" data-markdown-parsed="true" data-index-h="3" class="presentSlide"}`;
    const end = `:::`;
    const matches = [...mdcontent.matchAll(splitter)];
    if (!matches || matches.length === 0) return mdcontent;
    const newcontent = '\n' + start + '\n' + mdcontent.replaceAll(splitter, end + '\n\n' + start + '\n') + '\n' + end + '\n';
    return newcontent;
}

export function scaleSlide () {
    var slide = document.getElementsByClassName("presentSlide-active")[0];
    const {width, height} = slide.getBoundingClientRect();
    const wrapper = document.getElementsByClassName("scaleable-wrapper")[0];
    const {width: availableWidth, height: availableHeight} = slide.getBoundingClientRect();

    const scale = Math.min(
        availableWidth / width,
        availableHeight / height
    );

    const transform = `translate(0%, 0%) scale(${scale})`;
    wrapper.style.transform = transform;
}
