import { v4 as uuidv4 } from 'uuid';
import {STORAGE_CODE, STORAGE_MENU, STORAGE_MANUAL} from './config';

class Storage {
    constructor (localStorage, sessionStorage) {
        this.storage = localStorage;
        this.sessionStorage = sessionStorage;

        this.idKey = 'MARKS_SESSION_ID';
        this.identifier = this.sessionStorage.getItem(this.idKey);
        if (!this.identifier) {
            this.identifier = uuidv4();
            this.sessionStorage.setItem(this.idKey, this.identifier);
        }

        this.isTopFrame = self == top;
        this.name = window.name;
        console.log('Storage', this.isTopFrame, this.name, this.identifier);

        // this iframe has a parent and we want the session to maintain the same session storage
        // the name is given by the parent frame with (react-iframe {"id" <id>})
        if (!this.isTopFrame && this.name && window.parent.name) {
            this.identifier = window.parent.name + this.name;
        }
        else window.name = this.identifier;
        console.log('Storage1', this.isTopFrame, this.name, this.identifier);
    }

    setItem (key, value) {
        const _key = this.identifier + key;
        // console.log('setItem', _key, value.slice(0, 100));
        this.storage.setItem(_key, value);
    }

    getItem (key) {
        const _key = this.identifier + key;
        // console.log('getItem', _key, this.storage.getItem(_key) ? this.storage.getItem(_key).length : null);
        return this.storage.getItem(_key);
    }
}

const storage = new Storage(localStorage, sessionStorage);

export {
    storage,
    Storage,
}

async function init () {
    const extensions = {
        label: 'storage',
        items: [
            {
                label: 'set',
                value: (key, value) => {
                    return storage.setItem(key, value);
                }
            },
            {
                label: 'get',
                value: (key) => storage.getItem(key),
            },
            {
                label: 'get-code',
                value: () => storage.getItem(STORAGE_CODE),
            },
            {
                label: 'set-code',
                value: (value) => storage.setItem(STORAGE_CODE, value),
            },
            {
              label: 'get-manual',
              value: () => storage.getItem(STORAGE_MANUAL),
            },
            {
                label: 'set-manual',
                value: (value) => {
                    storage.setItem(STORAGE_MANUAL, value);
                },
            },
            {
                label: 'get-contracts',
                value: () => storage.getItem(STORAGE_MENU),
            },
            {
                label: 'set-contracts',
                value: (value) => storage.setItem(STORAGE_MENU, value),
            },
        ]
    }

    return {extensions};
}

export default init;
