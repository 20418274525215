import logger from '@ark-us/logger';
import { markEmit } from './communication';
let _logger = logger();

async function init () {
    const extensions = {
        label: 'logger',
        items: [
            {
                label: 'override',
                value: overrideLogger,
            },
            {
                label: 'clear',
                value: () => {
                    _logger = logger();
                }
            },
            {
                label: '',
                value: (...args) => {
                    console.log('--logger args0', ...args)
                    if (args.length && args[0]['0']) args = Object.values(args[0]);
                    const level = args.splice(0, 1);

                    const _args = args.map(v => {
                        if (v && typeof v === 'object' && v.log) {
                            // {log: true, actions: [{type: "button", "title": "",}]}
                            return {
                                ...v,
                                // FIXME
                                actions: Object.values(v.actions).map(action => {
                                    return {
                                        ...action,
                                        clickCallback: (v) => {
                                            if (!v || !v.src || !v.src.message) return;
                                            // FIXME
                                            const data = v.src.message[0];
                                            data.data.args = Object.values(data.data.args);

                                            markEmit(v.src.message[0]);
                                        }
                                    }
                                })
                            }
                        }
                        return v;
                    })

                    _logger[level](..._args);
                    const logs = _logger.get();
                    const _logs = {
                        string: {value: logs.string, type: 'verbatim'},
                        object: {value: logs.object, type: 'verbatim'},
                    }
                    return _logs;
                },
            },
        ]
    }
    return {extensions};
}

export default init;

function getInjectedConsole (tobereplaced) {
    return `
window._${tobereplaced} = ${tobereplaced};
window._console = console;
_console.log('---_${tobereplaced}', _${tobereplaced});

window.WrappedConsole = class WrappedConsole {
    log (...args) {
        this.request('log', args);
    }
    info () {}
    error () {}
    debug () {}
    warn () {}
    request (level, args) {
        _console.log('REQUEST', level, ...args);
        args.push('origin.' + window.name);
        // TODO clean up args, to be sent by postMessage (e.g. no functions);
        try {
            top.postMessage({type: 'mark.logger.Log', data: [level, ...args]});
        } catch (e) {
            console.log(level, ...args);
            console.debug(e);
        }
    }
}

${tobereplaced} = new WrappedConsole();

_console.log('---${tobereplaced}', ${tobereplaced});

    `
}

function overrideLogger (loggerName) {
    loggerName = loggerName || 'console';
    const injected = getInjectedConsole(loggerName);

    const callback = function(e) {
        const data = e.data;
        if (data.type !== 'mark.logger.Log') return;
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();

        const newdata = {...data, type: 'mark.logger.DisplayLog'};
        window.postMessage(newdata);
    }

    window.removeEventListener('message', callback);
    window.addEventListener('message', callback);
    return injected;
}
