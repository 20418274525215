import * as d3require from "d3-require";

window.PROV = {};
window.PROV.packages = {};
window.d3require = d3require;
let require1, require2, require3;

// strip version - e.g. name@1.0.19
const packagename = name => {
    let v = name.replace(/-/g, '_').split('@');
    if (v.length === 1) v = v[0];
    else v = v[v.length - 2];
    v = v.split('/');
    return v[v.length - 1];
}

  const tryreq = async (req, name) => {
    let instance;
    try {
        instance = await req(name);
    } catch(e) {
      console.log(e);
    }
    return instance;
}

(async () => {
    require1 = window.require1 = await d3require.requireFrom(async name => {
        return `https://bundle.run/${name}`;
    });

    require2 = window.require2 = await d3require.requireFrom(async name => {
        return `https://unpkg.com/${name}`;
    });

    require3 = window.require3 = await d3require.requireFrom(async name => {
        return `https://cdn.jsdelivr.net/npm/${name}`;
    });
})();

const _require = window.PROV.require = async function(name, alias) {
    name = await name;
    alias = alias === 'nil' ? null : alias;
    const displayName = packagename(name);
    console.log('displayName', displayName);

    if (window.PROV.packages[displayName]) {
      return window.PROV.packages[displayName];
    }
    if (alias && window.PROV.packages[alias]) {
      return window.PROV.packages[alias];
    }

    let instance;
    instance = await tryreq(d3require.require, name);
    if (!instance) instance = await tryreq(require1, name);
    if (!instance) instance = await tryreq(require2, name);
    if (!instance) instance = await tryreq(require3, name);

    console.log('instance', instance);

    if (!instance && name.startsWith("http")) {
      const urlfetch = await d3require.requireFrom(async n => name);
      instance = await tryreq(urlfetch, name);
    }

    if (!instance) {
      throw new Error('Npm module not loaded: ' + name);
    }
    window.PROV.packages[displayName] = instance;
    const dn = await window.require.resolve(displayName);
    window.require.cache[dn] = instance;
    if (alias) {
        window.PROV.packages[alias] = instance;
        const da = await window.require.resolve(alias);
        window.require.cache[da] = instance;
    }

    console.log('instance', instance);
    return instance;
}

window.require = window.PROV.require;
Object.keys(d3require.require).forEach(key => {
    window.require[key] = d3require.require[key];
});
window.require.cache = {};

export default _require;
