import React from 'react';
import { Modal } from 'antd';

export default function ModalComponent (props = {}) {
    const {children = []} = props;
    return (
        <>
        <Modal {...props}>{children}</Modal>
        </>
    );
};
