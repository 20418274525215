const examples = [
    {
        label: 'user interface',
        items: [
            {
                label: 'Text',
                value: `::Text[Some text]{label="alabel"}`,
            },
            {
                label: 'Input',
                value: `::Input[]{placeholder="a placeholder" label="alabel"}`,
            },
            {
                label: 'Button',
                value: `::Button[clickme]{}`,
            },
            {
                label: 'Button Icon',
                value: `::Button[edit]{icon=true}`,
            },
            {
                label: 'Link',
                value: `::Link[created by 0x833D4c383Bef3163d447F90DA78f97379bDcC04d]{src="/?ipfs=QmPMwmEwZKQ12ipTaezA5ESBN2eVVxnWfv6eL2vha4WLzZ&input=%7B%22amount_wei%22%3A%7B%22_hex%22%3A%220x071afd498d0000%22%2C%22_isBigNumber%22%3Atrue%7D%2C%22somethingnotexisting%22%3A%7B%22name%22%3A%22ropsten%22%2C%22chainId%22%3A3%2C%22ensAddress%22%3A%220x00000000000C2E074eC69A0dFb2997BA6C7d2e1e%22%7D%2C%22amount%22%3A%220.002%22%2C%22gasLimit%22%3A%2235000%22%2C%22gasPrice%22%3A%2215%22%2C%22receiver%22%3A%220x833D4c383Bef3163d447F90DA78f97379bDcC04d%22%2C%22reason%22%3A%22donation%20for%20creating%20a%20useful%20mark%22%7D"}`,
            },
            {
                label: 'Image',
                value: `::Image[]{src="https://www.imagesource.com/wp-content/uploads/2019/06/Rio.jpg" alt="image" width=200}`,
            },
            {
                label: 'Video',
                value: `::Video[]{src="https://www.youtube.com/embed/N8Es2iPLGzY" width="560" height="315"}`,
            },
            {
                label: 'Math',
                value: `::Math[\\\\text{\\\\(\\\\frac {5} {7}\\\\)}]{label="answer"}`,
            },
            {
                label: 'Contract',
                value: `::Contract[Name: @]{address="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2" name="ERC20" function="name"}`,
            },
        ]
        },
        {
            label: 'commands',
            items: [
                {
                    label: 'eth-provider-getNetwork',
                    value: `::Text[chainId: @chainId]{command="eth-provider-getNetwork"}`,
                },
                {
                    label: 'eth-provider-getBalance',
                    value: `::Input[]{placeholder="0x..." label="ownerAddress"}
            ::Text[Balance: @]{command="eth-provider-getBalance" input="ownerAddress"}`,
                },
                {
                    label: 'eth-pay',
                    value: `::Button[fingerprint]{icon=true command="eth-pay" input="receiver,amount_wei,gasLimit,gasPrice" output="receipt" size=30}`,
                },
            ]
    },
]

export default examples;
