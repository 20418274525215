import {ethers} from 'ethers';
import {evmjs, utils} from 'ewasm-jsvm';
import {default as _taylor} from '@ark-us/taylor';

const {strip0x, uint8ArrayToHex, toBN} = utils;

function updateMemValue (pos, mem, key, _value, prevMemory) {
    let item = mem[key];
    if (!item) {
        const memkey = (key * 32).toString(16).padStart(4, '0');
        item = {
            index: memkey,
        }
    }
    const value = strip0x(uint8ArrayToHex(_value))
    let changed = pos;
    if (prevMemory[key] && prevMemory[key].value == value) {
        changed = prevMemory[key].changed;
    }
    Object.assign(item, {
        _value,
        value,
        ascii: ascii(_value),
        changed,
        diff: pos - changed,
        classes: 'debug_row row_opcode_' + changed,
    });
    return item;
}

export function prepMemory (pos, changedMemory = [], prevMemory = {}) {
    let _mem = cloneMem(prevMemory);
    const [offset, value, iswritten] = changedMemory;
    if (typeof offset === 'undefined') return _mem;
    const start = Math.floor(offset / 32);
    const end = Math.ceil((offset + value.length) / 32);
    const positions = end - start;
    let soffset = offset - (start * 32);
    let cl = '';
    if (typeof iswritten === 'undefined') {
        cl = ' row_storage_' + iswritten;
    }
    if (_mem[start] && _mem[start].state !== iswritten) {
        cl = ' row_storage_2';
    }
    if (iswritten === 1) {
        let remaining = [...value];
        for (let slot = 0; slot < positions; slot++) {
            const key = start + slot;
            let _value = prevMemory[key] ? prevMemory[key]._value : new Uint8Array(32);
            const valuelen = 32 - soffset;
            _value = replaceInArray(_value, soffset, remaining.slice(0, valuelen))

            const item = updateMemValue(pos, _mem, key, _value, prevMemory);
            item.state = iswritten;
            item.classes += cl;
            _mem[key] = item;
            soffset = 0;
            remaining = remaining.slice(valuelen);
        }
    }
    return _mem;
}

// [Uint8Array, ... ]
export function prepStack (pos, stack, _prevstack) {
    const len = stack.length;
    const prevstack = [..._prevstack].reverse();
    return stack.map((_value, i) => {
        const index = len - i - 1;
        const value = strip0x(uint8ArrayToHex(_value));
        let changed = pos;

        const previ = i;
        if (previ > -1 && prevstack[previ] && prevstack[previ].value == value) {
            changed = prevstack[previ].changed;
        }
        const item = {
            pos,
            index,
            value,
            ascii: ascii(_value),
            changed: changed,
            diff: pos - changed,
            classes: 'debug_row row_opcode_' + changed,
        }
        return item;
    }).reverse();
}

export function slicePieces (str, slotsize) {
    return [...new Array(Math.ceil(str.length / slotsize)).keys()].map(i => str.slice(i * slotsize, (i + 1) * slotsize));
}

export function ascii (arr) {
    return [...arr].map(v => {
        const code = (v && v > 32 && v < 123) ? v : 63;
        return String.fromCharCode(code);
    }).join('');
}

export function prepStorage (pos, storage, prevStorage, changedPosition = []) {
    const _storage = {};
    const [start, v, iswritten] = changedPosition;
    // console.log('changedPosition', start, v, iswritten);
    Object.keys(storage).forEach((key, i) => {
        const skey = strip0x(key);
        const value = strip0x(uint8ArrayToHex(storage[key]));
        let changed = pos;
        let cl = '', state;
        if (start === skey) {
            state = iswritten;
            cl = ' row_storage_' + iswritten;
        }
        if (prevStorage[skey] && prevStorage[skey].value == value) {
            changed = prevStorage[skey].changed;
            if (start === skey && typeof prevStorage[skey].state !== 'undefined' && prevStorage[skey].state !== state) {
                cl = ' row_storage_2';
            }
            if (start === skey && !state) state = prevStorage[skey].state;
        }
        const item = {
            index: skey,
            value,
            ascii: ascii(storage[key]),
            changed,
            diff: pos - changed,
            classes: 'debug_row row_opcode_' + changed + cl,
            state,
        }
        _storage[skey] = item;
    });
    return _storage;
}

const DEFAULT_TX_INFO = {
    gasLimit: 2000000,
    gasPrice: 10,
    from: '0x79f379cebbd362c99af2765d1fa541415aa78508',
    value: 0,
}

function cloneMem (mem) {
    const _mem = {};
    Object.keys(mem).forEach((key) => {
        _mem[key] = {...mem[key]};
    });
    return _mem;
}

function replaceInArray (iniarray, offset, value) {
    if (!iniarray) iniarray = new Uint8Array(32);
    [...value].forEach((v, i) => {
        iniarray[offset + i] = v;
    });
    return iniarray;
}
let cache = {}
export async function debugCode (runtimeBytecode, txData, options) {
    let key = ethers.utils.id(JSON.stringify(txData) + runtimeBytecode);
    if (!txData || !key) key = Math.floor(Math.random() * 1000000);
    if (cache[key]) return cache[key];
    else {
        cache[key] = debugCodeInner(runtimeBytecode, txData, options);
        return cache[key];
    }
}

let cachedEvmjs;
export async function debugCodeInner (runtimeBytecode, txData, options = {}) {
    let _evmjs, runtime, tx, result, error;
    window.providerDebug = options.provider;
    if (!txData.data) txData.data = '0x';
    if (txData.hash) {
        _evmjs = evmjs(options);
        try {
            runtime = await _evmjs.simulateTransaction(txData.hash);
        } catch (e) {
            console.error('debugger', e);
            error = e.message;
            runtime = e.runtime;
        }
        result = runtime.result;
        tx = runtime.txInfo;
        console.log('*////*debug**----runtime', runtime)
    }
    else if (!runtimeBytecode && txData.bytecode) {
        _evmjs = evmjs();
        const simulatedAddress = txData.to;
        tx = {
            ...DEFAULT_TX_INFO,
            gasLimit: 14000000,
            ...txData,
        };
        delete tx.bytecode;
        delete tx.args;
        delete tx.to;
        try {
            runtime = await _evmjs.deploy(txData.bytecode, [], simulatedAddress)(txData.args, tx);
        } catch (e) {
            console.error('debugger', e);
            error = e.message;
            runtime = e.runtime;
        }
        result = runtime.address;
    }
    else if (runtimeBytecode) {
        _evmjs = cachedEvmjs || evmjs(options);
        cachedEvmjs = _evmjs;
        runtime = await _evmjs.runtimeSim(runtimeBytecode, [], txData.to);
        tx = {
            ...DEFAULT_TX_INFO,
            ...txData,
            data: txData.data.slice(0, 2) === '0x' ? txData.data : '0x' + txData.data,
            from: txData.from || DEFAULT_TX_INFO.from,
        };
        if (tx.gasPrice && tx.gasPrice._hex) tx.gasPrice = tx.gasPrice._hex;
        if (tx.gasLimit && tx.gasLimit._hex) tx.gasLimit = tx.gasLimit._hex;
        // console.log('tx', tx);

        try {
            result = await runtime.mainRaw(tx);
        } catch (e) {
            console.error('debugger.mainRaw ', e);
            error = e.message;
        }

        if (!result || result.length === 0) result = null;
        else result = uint8ArrayToHex(result);
    }
    else return [{value: [], type: 'verbatim'}, {count: 0, result: null, dappresult: null, receipt: {}, error: 'No bytecode'}, () => {}];

    let opcodes = [];
    let pcToStep = [];
    let prevMemory = {};
    let prevStorage = {};
    let pcCount = {};
    let gasUsed = toBN(0);
    runtime.logs.forEach((log, i) => {
        const {stack, output, name, input, context, contractAddress, changed = {}, position, gasCost, addlGasCost, refundedGas, logs} = log;
        const contract = context[contractAddress] || {};
        const _gasCost = gasCost ? toBN(gasCost) : toBN(0);
        const _addlGasCost = addlGasCost ? toBN(addlGasCost) : toBN(0);
        const _refundedGas = refundedGas ? toBN(refundedGas) : toBN(0);
        gasUsed = gasUsed.add(_gasCost).add(_addlGasCost).sub(_refundedGas);

        let _logs = logs ? logs : [];
        _logs = _logs.map((v, i) => {
            const topics = v.topics.map(t => '0x' + t.toString(16).padStart(64, '0'));
            return {
                index: i,
                address: '0x' + uint8ArrayToHex(v.address).substr(26),
                data: uint8ArrayToHex(v.data),
                topics,
                _topics: topics.join(' '),
            }
        });

        const prev = opcodes[i - 1] || {};
        const _stack = prepStack(i, stack, prev.stack ? prev.stack.value : []);
        const _memory = prepMemory(i, changed.memory, prevMemory);
        const __memory = Object.values(_memory).map(v => {const t = {...v}; delete t._value; return t})
        const _storage = prepStorage(i, contract.storage, prevStorage, changed.storage);
        const __storage = Object.values(_storage);
        if (!pcCount[position]) pcCount[position] = 1;
        else pcCount[position] ++;

        if (!pcToStep[position]) pcToStep[position] = [];
        pcToStep[position].push(i);

        opcodes.push({
            pc: position,
            pcCount: pcCount[position],
            step: i,
            name,
            stack: {value: _stack, type: 'verbatim'},
            stackLength: _stack.length,
            output: {value: output, type: 'verbatim'},
            input: {value: input, type: 'verbatim'},
            memory: {value: __memory, type: 'verbatim'},
            memoryLength: __memory.length,
            storage: {value: __storage, type: 'verbatim'},
            storageLength: __storage.length,
            classes: 'debug_row row_opcode_' + i,
            gasCost: _gasCost.toNumber(),
            addlGasCost: _addlGasCost.toNumber(),
            refundedGas: _refundedGas.toString(10),
            gasUsed: gasUsed.toString(10),
            logs: _logs,
        });
        prevMemory = _memory;
        prevStorage = _storage;
    });
    opcodes[0].pc = '';
    // opcodes = opcodes.slice(0, 200);
    console.log('opcodes', opcodes.length, new Date().getTime());

    const lastblock = await options.provider.getBlock();
    const _gasUsed = runtime.gas ? runtime.gas.used.toNumber() : gasUsed;
    const receipt = {
        gasUsed: _gasUsed,
        logs: [],
        cumulativeGasUsed: _gasUsed,
        status: error ? 0 : 1,
        to: tx.to,
        from: tx.from,
        transactionHash: lastblock.transactions[0] || '0x01c68223adbf8d83015552ce2486ba8e36205d74d7275726dd2f7adaad636fa8',
    }
    let dappresult;
    if (result) dappresult = JSON.parse(JSON.stringify(result));
    const pcToStepFn = (_pc, _iteration) => {
        const v = _taylor.interop.tay2js(_pc);
        let iteration = _iteration ? parseInt(_taylor.interop.tay2js(_iteration)) : null;
        iteration = typeof iteration === 'number' ? iteration : 0;
        const steps = pcToStep[v] || [];
        const step = steps[iteration] ? steps[iteration] : steps[0];
        return _taylor.interop.jsToTay(step);
    }
    return [{value: opcodes, type: 'verbatim'}, {count: opcodes.length, result: result, dappresult, receipt, error}, pcToStepFn];
}
