import Ipfs from 'ipfs-core';
import { base16, base58, base64 } from "multiformats/bases/base16";
import {waitFor} from './utils';

let node;

async function init (ipfs) {
  node = ipfs;
  const extensions = {
      label: 'ipfs',
      items: [
          {
              label: 'create',
              value: async () => {
                  if (node) return node;
                  console.debug('Creating another IPFS node', node)
                  return await Ipfs.create();
              }
          },
          {
              label: 'CID',
              value: (id, base) => {
                if (base === 'base16') return parseCID(id, base16.decoder);
                if (base === 'base58') return parseCID(id, base58.decoder);
                if (base === 'base64') return parseCID(id, base64.decoder);
                return parseCID(id);
              }
          },
          {
            label: 'CID-to-base16',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base16);
            },
          },
          {
            label: 'CID-to-base58',
            value: (cid) => {

              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV0 ? cid.toV0() : cid;
              return cid.toString();
            },
          },
          {
            label: 'CID-to-base64',
            value: (cid) => {
              cid = cid.cid ? cid.cid : cid;
              cid = cid.toV1 ? cid.toV1() : cid;
              return cid.toString(base64);
            },
          },
          {
              label: 'get',
              value: ipfsGet,
          },
          {
              label: 'set',
              value: ipfsSet,
          },
          {
            label: 'set-file',
            value: ipfsSetFile,
        },
      ]
  }

  return {extensions};
}

export function parseCID (strValue, baseDecoder) {
  return Ipfs.CID.parse(strValue, baseDecoder);
}

async function getFile (cid) {
  let content = '';
  let stream;
  const decoder = new TextDecoder();
  try {
    stream = node.cat(cid);
  } catch(e) {
    return;
  }
  for await (const _chunk of stream) {
    console.log('_chunk', _chunk);
    content += decoder.decode(_chunk);
  }
  return content;
}

const waitTime = 5000;
export async function ipfsGet (id) {
    if (!node) node = await Ipfs.create();
    console.log('ipfsGet', id);
    let result;
    const cid = new Ipfs.CID.parse(id);
    console.log('ipfsGet cid', cid);
    const operation = () => {
      return getFile(cid);
    }
    const content = await waitFor(operation, waitTime);

    if (content) {
      try {
        result = JSON.parse(content);
      } catch(e) {
        result = content;
      }
    }
    else {
      const operation = () => {
        return fetch('https://ipfs.io/ipfs/' + id);
      }
      const response = await waitFor(operation, waitTime);
      if (response) {
        try {
          result = await response.text();
        } catch (e) {
          console.error('Could not decode file', id);
        }
      }
      if (result) {
        try {
          result = JSON.parse(result);
        } catch (e) {}
      }
    }
    return result
}

// default  {type : 'text/html'}
export async function ipfsSet (obj, options) {
  console.log('ipfsSet', obj, options);
  let type;
  let file = obj;
  if (typeof obj === 'object') {
    type = 'application/json';
    file = JSON.stringify(obj);
  }
  const _options = {
    type,
    ...options,
  }
  return ipfsSetFile(new Blob([file], _options));
}

export async function ipfsSetFile (file) {
  console.log('ipfsSetFile', file);
  if (!node) {
    node = await Ipfs.create();
  }
  try {
    const saved = await node.add(file);
    console.log('---saved', saved);
    return saved;
  } catch(e) {
    console.debug(e);
    return;
  }
}


export default init;

