import React, { useState, useEffect, useRef } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';
import classNames from 'classnames';
import { Table } from 'antd';

function VirtualTable(props) {
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);
    const widthColumnCount = columns.filter(({ width }) => !width).length;
    const mergedColumns = columns.map((column) => {
        if (column.width) {
            return column;
        }

        return { ...column, width: Math.floor(tableWidth / widthColumnCount) };
    });
    const gridRef = useRef();
    const rowHeight = props.rowHeight || 54;
    const rowStyle = {
        borderBottom: '1px solid #303030',
        ...(props.rowStyle || {}),
        overflowX: 'visible',
        width: '600px'
    };
    const rowClassName = props.rowClassName;

    const [connectObject] = useState(() => {
        const obj = {};
        Object.defineProperty(obj, 'scrollLeft', {
        get: () => null,
        set: (scrollLeft) => {
            if (gridRef.current) {
                gridRef.current.scrollTo({
                    scrollLeft,
                });
            }
        },
        });
        return obj;
    });

    const resetVirtualGrid = () => {
        if (gridRef.current) {
            gridRef.current.resetAfterIndices({
            columnIndex: 0,
            shouldForceUpdate: true,
            });
        }
    };

    useEffect(() => resetVirtualGrid, [tableWidth]);

    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
        ref.current = connectObject;
        const totalHeight = rawData.length * rowHeight;

        return (
            <Grid
                ref={gridRef}
                className="virtual-grid"
                columnCount={mergedColumns.length}
                columnWidth={(index) => {
                    const { width } = mergedColumns[index];
                    return totalHeight > scroll.y && index === mergedColumns.length - 1
                        ? width - scrollbarSize - 1
                        : width;
                }}
                height={scroll.y}
                rowCount={rawData.length}
                rowHeight={() => rowHeight}
                width={tableWidth}
                onScroll={({ scrollLeft }) => {
                    onScroll({
                        scrollLeft,
                    });
                }}
            >
            {({ columnIndex, rowIndex, style }) => {
                const record = rawData[rowIndex];
                const classes = rowClassName ? record[rowClassName] : '';
                return (
                    <div
                        className={classNames('virtual-table-cell', {
                        'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
                        }, classes)}
                        style={{...style, ...rowStyle}}
                        onClick={() => props.onRowClick(record, rowIndex)}
                    >
                        {record[mergedColumns[columnIndex].dataIndex]}
                    </div>
                )
            }}
            </Grid>
        );
    };

    const _props = {...props};
    delete _props.onRowClick;

    return (
        <ResizeObserver
        onResize={({ width }) => {
            setTableWidth(width);
        }}
        >
        <Table
            {..._props}
            className="virtual-table"
            columns={mergedColumns}
            pagination={false}
            components={{
            body: renderVirtualList,
            }}
        />
        </ResizeObserver>
    );
}

export default VirtualTable;
