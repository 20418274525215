export {default as DEFAULT_ABIS} from './abi';
export {default as DEFAULT_ADDRESSES} from './addresses';
import laws from './laws';
import countries from './countries';
import categories from './categories';
import skills from './skills';

function getItem (value) {
    return {
        title: value.label,
        value: value.value,
        key: value.value,
        parent: value.parent,
    }
}

function _buildTree (list, obj, index = 0) {
    if (list[index] && list[index].parent === obj.value) obj.children = [];
    else return [index, obj];

    let i;
    for (i = index; i < list.length; i++) {
        const item = list[i];
        if (item.parent === obj.value) {
            const [newindex, kid] = _buildTree(list, getItem(item), i + 1);
            obj.children.push(kid);
            i = newindex - 1;
        }
        else {
            break;
        }
    }
    return [i, obj];
}

function buildTree (list, obj) {
    const [i, _obj] = _buildTree(list, obj);
    return obj;
}

// const _laws = buildTree(laws.slice(1), getItem(laws[0]));
// const countries = buildTree(jurisdictions.slice(1), getItem(jurisdictions[0]));
// console.log('countries', JSON.stringify(countries));
// console.log('_laws', JSON.stringify(_laws));

// const _categories = buildTree(categories.slice(1), getItem(categories[0]));
// console.log('categories', JSON.stringify(_categories));

// const _skills = buildTree(skills.slice(1), getItem(skills[0]));
// console.log('skills', JSON.stringify(_skills));

export const DEFAULT_TAGS = laws.children;
export const DEFAULT_JURISDICTION = countries.children;
export const DEFAULT_CATEGORIES = categories.children;
export const DEFAULT_SKILLS = skills.children;
