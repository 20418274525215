import VueFormGenerator from "vue-form-generator";

let typeMap = {
    uint: {
        type: 'number',
        inputType: 'number',
        validator: VueFormGenerator.validators.integer,
        min: 0,
        max: 2 ** 256,
        placeholder: 'uint256',
    },
    int: {
        type: 'number',
        inputType: 'number',
        validator: VueFormGenerator.validators.integer,
        min: -(2 ** 256),
        max: 2 ** 256,
        placeholder: 'int256',
    },
    bool: {
        type: 'bool',
        values: [true, false],
    },
    address: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        placeholder: 'address',
        // pattern: '0x[a-fA-F0-9]{40}',
    },
    string: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        min: 20,
        max: 22,
        placeholder: 'string',
    },
    bytes: {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.string,
        placeholder: 'bytes',
    },
    'uint[]': {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.integer,
    },
    'int[]': {
        type: 'string',
        inputType: 'text',
        validator: VueFormGenerator.validators.integer,
    },
};

for (let i = 8; i <= 256; i += 8) {
    typeMap[`uint${i}`] = Object.assign({}, typeMap.uint);
    typeMap[`uint${i}`].placeholder = `uint${i}`;
    typeMap[`uint${i}[]`] = Object.assign({}, typeMap['uint[]']);

    typeMap[`int${i}`] = Object.assign({}, typeMap.int);
    typeMap[`int${i}`].placeholder = `int${i}`;
    typeMap[`uint${i}[]`] = Object.assign({}, typeMap['int[]']);
}

export function ioToFormSchema(io, opts = {}, values) {
    const schema = {
        type: typeMap[io.type].type,
        inputType: typeMap[io.type].inputType,
        enum: typeMap[io.type].values,
        // title: io.name,
        title: '',
        default: values[io.name],
        ...opts
    }

    // if (io.type.includes('[]')) {
    //     schema.items = {type: typeMap[io.type].inputType};
    // }

    const uiSchema = {
        "ui:placeholder": io.name + ':' + typeMap[io.type].placeholder,
        "ui:options": {
            inputType: typeMap[io.type].inputType,
        }
    }
    return {schema, uiSchema};
}

export function inputToFormSchema(io, values) {
    return ioToFormSchema(io, {required: true}, values)
}

export function outputToFormSchema(io, values) {
   return ioToFormSchema(io, {readonly: true}, values);
}

export function abiFuncToFormSchema(abi, values) {
    let schema = {
        "title": "",
        "type": "object",
        // "required": [],
        "properties": {
        }
    }
    let uiSchema = {}

    abi.inputs.forEach(io => {
        const schemas = inputToFormSchema(io, values);
        schema.properties[io.name] = schemas.schema;
        uiSchema[io.name] = schemas.uiSchema;
    })
    return {schema, uiSchema};
}

export function abiToFormSchema(abi) {
    let _schema = {
        "title": "",
        "type": "object",
        "properties": {
        }
    }
    let _uiSchema = {};

    abi.forEach((v) => {
        const {schema, uiSchema} = abiFuncToFormSchema(v);
        _schema.properties[v.name] = schema;
        _uiSchema[v.name] = uiSchema;
    });

    return {schema: _schema, uiSchema: _uiSchema};
}
