import QRCode from 'qrcode';
import {clipboardCopy} from './utils';

async function init () {
    const extensions = {
        label: 'qr',
        items: [
            {
                label: 'generate',
                value: generateQrCode,
            },
        ]
    }
    return {extensions};
}

export default init;

export function generateQrCode (id, text) {
    console.log('generateQrCode', text);
    var canvas = document.getElementById(id);
    document.getElementById('AppFrontStage').style.display = 'block';
    QRCode.toCanvas(canvas, text, function (error) {
        if (error) console.error(error)
        console.log('qrcode generated!');
        canvas.onclick = () => {
            clipboardCopy(text);
        }
    });
}
