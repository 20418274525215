import Hammer from 'hammerjs';

async function init () {
    const extensions = {
        label: 'draggable',
        items: [
            {
                label: '',
                value: dragSupport,
            },
        ]
    }
    return {extensions};
}

export default init;

function dragSupport (domid, callbacks = {}) {
    if (!domid) return;
    const myElement = document.getElementById(domid);
    // console.log('dragSupport', domid, myElement);
    if (!myElement) return;
    var hammertime = new Hammer(myElement, {});
    if (callbacks.pan && callbacks.pan.callback) {
        if (callbacks.pan.options && typeof callbacks.pan.options === 'object') {
            hammertime.get('pan').set(callbacks.pan.options);
        }
        hammertime.on('pan', function(ev) {
            const delta = {
                deltaX: ev.deltaX > 0 ? ev.deltaX : 0,
                _deltaX: ev.deltaX < 0 ? Math.abs(ev.deltaX) : 0,
                deltaY: ev.deltaY > 0 ? ev.deltaY : 0,
                _deltaY: ev.deltaY < 0 ? Math.abs(ev.deltaY) : 0,
            }
            callbacks.pan.callback({center: ev.center, delta});
        });
    }
    if (callbacks.press && callbacks.press.callback) {
        if (callbacks.press.options && typeof callbacks.press.options === 'object') {
            hammertime.get('press').set(callbacks.press.options);
        }
        hammertime.on('press', function(ev) {
            // console.log('press', ev);
            callbacks.press.callback({center: ev.center});
        });
    }
}
