import React from 'react';
import './App.css';
import './presentation.css';
import taylor, {initialize, setStyle, setStyleCss} from './taylor';
import {ipfsGet} from './ipfs';
import {contractsHydrate} from './marks';
import {startIpfs, ipfsCleanup} from './components/ipfs/useIpfs';
import {storage} from './storage';
import {defaultMark} from './mdtext';
import {STORAGE_CODE, STORAGE_MENU, DISPLAY_TYPE, STORAGE_MANUAL} from './config';
import {locationParams} from './browser';

window.marks = (() => {
  const env = taylor.envNew();
  const _eval = expr => taylor.eval(expr, env);
  const _extend = (key, value) => taylor.extendfn(key, value, env);
  const evalCommand = (command = {}) => {
    let {method, args=[]} = command;
    if (!method) return;
    console.log('marks evalCommand', method, args);
    args = args || [];
    if (!(args instanceof Array)) args = [args];
    try {
      const _args = args.map(v => taylor.interop.jsToTay(v));
      const _argsstr = _args.map(v => taylor._.print(v));
      const _command = `(${method} ${_argsstr.join(' ')} )`;
      _eval(_command);
    } catch (e) {
      console.error('marks evalCommand', e);
    }
  }
  return {
    eval: _eval,
    extendfn: _extend,
    evalCommand,
  }
})()

let mdcontent = storage.getItem(STORAGE_CODE) || defaultMark;
let manual = storage.getItem(STORAGE_MANUAL);
const _menucontent = storage.getItem(STORAGE_MENU) || '[]';
let menucontracts = [];
try {
  menucontracts = JSON.parse(_menucontent);
} catch (e) {
  console.error('Menu contracts could not be parsed', e);
}

async function appInit (ipfs) {
  return initialize({ipfs});
}

const modeMap = {
  e: 'edit',
  p: 'play',
}

function App() {
  const [content, setContent] = React.useState(<p>Loading...</p>);
  console.info('STARTING APP')

  React.useEffect(() => {
    async function setup () {
      console.info('STARTING APP SETUP')
      let file;
      let ipfs = {};
      let styles;
      const params = locationParams() || {};
      let mode = modeMap[params.m] || 'production';
      if (mode === 'edit' && DISPLAY_TYPE !== 'BOTH') mode = 'play';

      const editorComponent = async (mdcontent, file, mode) => {
        let code;

        let fileContracts = [];
        if (file && file.contracts) {
          fileContracts = await contractsHydrate(file.contracts);
        }
        const contracts = file && file.contracts ? menucontracts.concat(fileContracts) : menucontracts;
        const menucontent = taylor._.print(taylor.interop.jsToTay(contracts));

        const _file = taylor._.print(taylor.interop.jsToTay(file));
        const __manual = manual ? `(quote ${JSON.stringify(manual)})` : 'nil';
        code = `(md-editor-toolbar {"displayType" "${DISPLAY_TYPE}" "value" (quote ${JSON.stringify(mdcontent)}) "manual" ${__manual} "contractdata" ${menucontent} "file" ${_file} "mode" "${mode}" })`;

        return code;
      }

      console.info('INITIAL APP INIT')
      await appInit(ipfs);

      if (params.s) {
        styles = await ipfsGet(params.s);
        try {
          if (typeof styles === 'string') {
            setStyleCss("importedStyles", styles);
          }
          else {
            const jsonStyles = taylor.interop.tay2js(await taylor.eval(styles.styles));
            setStyle("importedStyles", jsonStyles);
          }
        } catch (e) {
          console.warn('Imported styles are not a taylor object', styles, e);
          styles = null;
        }
      }

      if (!params.ipfs && params.content) {
        try {
          const contracts = JSON.parse(decodeURIComponent(params.contracts));
          file = {
            chain: params.chain,
            contracts,
            content: decodeURIComponent(params.content),
          };
        } catch (e) {console.debug(e)}

        let code = await editorComponent(mdcontent, file, mode);
        try {
          const c = await taylor.eval(code);
          setContent(c);
        } catch (e) {
          setContent(e.message);
        }
      }
      if (params.url || params.contentUrl) {
        let file = {};
        if (params.url) {
          let mdcontent = 'Loading';
          const url = decodeURIComponent(params.url);
          try {
            const response = await fetch(url);
            const text = await response.text();
            file = JSON.parse(text);
          } catch (e) {
            console.error(e);
            mdcontent = e.message
          };
        }
        if (params.contentUrl) {
          let mdcontent = 'Loading';
          const url = decodeURIComponent(params.contentUrl);
          try {
            const response = await fetch(url);
            mdcontent = await response.text();
          } catch (e) {
            console.error(e);
            mdcontent = e.message
          };
          file = {
            chainid: parseInt(params.chainid) || 3,
            contracts: [],
            content: mdcontent,
          }
        }

        let code = await editorComponent(mdcontent, file, mode);
        try {
          const c = await taylor.eval(code);
          setContent(c);
        } catch (e) {
          setContent(e.message);
        }
        return;
      }
      console.info('STARTING IPFS')
      const obj = await startIpfs();
      if (obj.ready && obj.ipfs) {
        console.info('IPFS STARTED')
        ipfs = obj.ipfs;
        await appInit(ipfs);
        console.info('APP INITIALIZED')

        if (params.ipfs) {
          mdcontent = 'Loading file from IPFS. It may take a while.';
          file = await ipfsGet(params.ipfs);
          let code = await editorComponent(mdcontent, file, mode);
          try {
            const c = await taylor.eval(code);
            setContent(c);
          } catch (e) {
            setContent(e.message);
          }
          return;
        }
      }

      let code = await editorComponent(mdcontent, file, mode);
      try {
        const c = await taylor.eval(code);
        setContent(c);
      } catch (e) {
        setContent(e.message);
      }

    }
    setup();

    return ipfsCleanup;
  }, []);

  return (
    <div className="App">
      {content}
      <div id="AppFrontStage" style={{display: 'none', position: 'absolute', top: '50%', left: '50%', transform: "translate(-50% , -50%)", zIndex: "20000"}}>
        <canvas id="qrcodeCanvas"></canvas>
        <button style={{position: "absolute", top: "0", right: "-25px"}} onClick={() => {
          document.getElementById('AppFrontStage').style.display = 'none';
        }}>x</button>
      </div>
    </div>
  );
}

export default App;
