import {getWindowById, iframeListen, iframeSend} from './iframe';

// type => {[iframeid]: window}
const imarks = {};
window.imarks = imarks;
// ids => window
const marksIds = {};
let iframeTOP = top;
let requestCount = 0;

async function init () {
    const extensions = {
        label: 'mark',
        items: [
            {
                label: 'init',
                value: markInit,
            },
            {
                label: 'register',
                value: markRegister,
            },
            {
                label: 'call',
                value: markCall,
            },
            {
                label: 'listen',
                value: markListen,
            },
            {
                label: 'emit',
                value: markEmit,
            },
        ]
    }

    return {extensions};
}

function getMark (id, api, contentWindow) {
    const mark = {};

    return mark;
}

const MSG = {
    REGISTER: 'mark.register',
    REGISTER_CONTINUE: 'mark.register.continue',
}

function _registerMark (markData) {
    const {type, id, version} = markData;
    if (!imarks[type]) imarks[type] = {};
    imarks[type][id] = markData;
}

export function markInit (callback) {
    iframeListen('self', {type: MSG.REGISTER}, function (message) {
        const {data} = message;
        const {type, id} = data;
        _registerMark(data);
        console.log('*mark*register*markInit', message);
        if (callback) callback({type, id});
    }, true);
}

// Register mark information with TOP
// type, version, api (name, type (function, event))
export function markRegister (markData) {
    if (!markData || !markData.type) return 'Provide mark type';
    if (!markData.version) return 'Provide mark version';
    iframeSend('self', {
        type: MSG.REGISTER_CONTINUE,
        data: {...markData},
    });
}

// register an iframe after it is loaded in a parent
export function registerMarkInstance (identifier, contentWindow) {
    contentWindow.marksIdentifier = identifier.id;
    console.log('mark.register post continue', identifier);
    iframeListen(contentWindow, {type: MSG.REGISTER_CONTINUE}, (message) => {
        const {data} = message;

        console.log('mark.register with top', message);
        iframeSend('top', {
            type: MSG.REGISTER,
            data: {...data, ...identifier},
        });
    });
}

// identifier type/iframeid
// message {method, args}
export function markCall (message) {
    requestCount ++;
    message.id = requestCount;
    return new Promise((resolve, reject) => {
        markListen({id: message.id, type: message.type}, (message) => {
            resolve(message.data);
        });
        markEmit(message);
    });
}

export function markRespond () {

}

export function markEmit (message) {
    if (message.type.slice(0, 5) !== 'mark.') {
        message.type = 'mark.' + message.type;
    }
    if (!message.from) message.from = window.marksIdentifier;
    console.log('----MARK EMIT', message);
    iframeSend('top', message);
}

export function markListen (filter, callback) {
    filter.type = 'mark.' + filter.type;
    iframeListen('top', filter, callback);
}

export default init;

