export function getWindowById (iframeid) {
    let contentWindow;
    if (typeof iframeid === 'object') return iframeid;

    if (iframeid === 'self') contentWindow = window;
    else if (iframeid === 'parent') contentWindow = window.parent;
    else if (iframeid === 'top') contentWindow = top;
    else {
        const elem = document.getElementById(iframeid);
        if (elem) contentWindow = elem.contentWindow;
    }
    return contentWindow;
}

async function init () {
    const extensions = {
        label: 'iframe',
        items: [
            {
                label: 'send',
                value: iframeSend,
            },
            {
                label: 'listen',
                value: iframeListen,
            },
        ]
    }

    return {extensions};
}

export function iframeSend (iframeid, message) {
    // message.type
    // message.data
    let contentWindow = getWindowById(iframeid);
    if (!contentWindow) return;
    const targetOrigin = '*';
    message.from = window.marksIdentifier;
    console.log('-IFRAME SEND-', iframeid, message);
    contentWindow.postMessage(message, targetOrigin);
}

export function iframeListen (iframeid, filter, callback, allowSelf = false) {
    const realtype = filter.type;
    let contentWindow = getWindowById(iframeid);
    if (!contentWindow) return;
    // console.log('---iframe listen', iframeid, realtype, contentWindow);
    contentWindow.addEventListener("message", (event) => {
        if (!event.data) return;
        if (!allowSelf && event.source.location.href == window.location.href) return;
        let message = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
        if (realtype && message.type !== realtype) return;
        console.log('-IFRAME RECEIVED-', message);
        callback(message, {location: {href: event.source.location.href}});
    }, false);
}


export default init;

