export const locationParams = () => {
    const location = window.location;
    if (!location.search || !location.search.includes('=')) return null;
    const params = {};
    location.search.slice(1).split('&').forEach((v) => {
        const [key, value] = v.split('=');
        params[key] = value;
    });
    return params;
}

async function init (taylor) {
    const extensions = {
        label: 'browser',
        items: [
            {
                label: 'location',
                value: () => window.location,
            },
            {
                label: 'location-params',
                value: locationParams,
            },
            {
                label: 'open',
                value: (url, target, features) => {
                    window.open(url, target, features);
                }
            },
            {
                label: 'document-getElementsByClassName',
                value: (className) => {
                    const v = document.getElementsByClassName(className);
                    return v;
                },
            },
        ]
    }
    return {extensions};
}

export default init;
