import * as d3 from 'd3';

function Cosy (svg, options) {
    const width = svg.clientWidth;
    const height = svg.clientHeight;
    this.xmin = options.xmin != null ? options.xmin : -10;
    this.xmax = options.xmax != null ? options.xmax : 10;
    this.ymin = options.ymin != null ? options.ymin : -10;
    this.ymax = options.ymax != null ? options.ymax : 10;
    const margin = {
        left: options.marginLeft != null ? options.marginLeft : options.margin != null ? options.margin: 50,
        right: options.marginLeft != null ? options.marginLeft : options.margin != null ? options.margin: 50,
        top: options.marginLeft != null ? options.marginLeft : options.margin != null ? options.margin: 50,
        bottom: options.marginLeft != null ? options.marginLeft : options.margin != null ? options.margin: 50,
    };
    this.xscale = d3.scaleLinear()
    .range([margin.left, width - margin.right])
    .domain([this.xmin, this.xmax]);
    this.yscale = d3.scaleLinear()
    .range([height - margin.bottom, margin.top])
    .domain([this.ymin, this.ymax]);
    d3.select(svg).append('defs')
    .append('clipPath')
    .attr('id', 'cosy-clip')
    .append('rect')
    .attr('x', this.xscale(this.xmin) - 10)
    .attr('y', this.yscale(this.ymax) - 10)
    .attr('width', this.xscale(this.xmax) - this.xscale(this.xmin) + 20)
    .attr('height', this.yscale(this.ymin) - this.yscale(this.ymax) + 20);
    const g = d3.select(svg).append('g')
    .attr('transform', 'translate(.5,.5)')
    .style('font', '8px sans-serif');
    this._cosy = g.append('g')
    .attr('class', 'cosy');
    this._drawing = g.append('g')
    .attr('class', 'drawing')
    .attr('clip-path', 'url(#cosy-clip)');
}

Cosy.prototype.xGridlines = function(ticks) {
    const gx = this._cosy.append('g')
    .attr('class', 'grid x');
    gx.selectAll('line').data(ticks).enter().append('line')
    .attr('class', 'line')
    .attr('x1', d => this.xscale(d))
    .attr('x2', d => this.xscale(d))
    .attr('y1', this.yscale(this.ymin))
    .attr('y2', this.yscale(this.ymax));
}

Cosy.prototype.yGridlines = function(ticks) {
    const gy = this._cosy.append('g')
    .attr('class', 'grid y');
    gy.selectAll('line').data(ticks).enter().append('line')
    .attr('class', 'line')
    .attr('x1', this.xscale(this.xmin))
    .attr('x2', this.xscale(this.xmax))
    .attr('y1', d => this.yscale(d))
    .attr('y2', d => this.yscale(d));
}

Cosy.prototype.xAxis = function(ticks, label='') {
    const gx = this._cosy.append('g')
    .attr('class', 'axis x');
    gx.append('line')
    .attr('class', 'line')
    .attr('x1', this.xscale(this.xmin))
    .attr('x2', this.xscale(this.xmax))
    .attr('y1', this.yscale(0))
    .attr('y2', this.yscale(0));
    gx.append('path')
    .attr('class', 'arrow')
    .attr('transform', `translate(${this.xscale(this.xmax)},${this.yscale(0)})`)
    .attr('d', 'M0,0 L-12,-4 L-12,4 L0,0');
    gx.append('text')
    .attr('class', 'label')
    .text(label)
    .attr('x', this.xscale(this.xmax))
    .attr('y', this.yscale(0) + 15)
    .attr('text-anchor', 'middle');
    const tickMarks = gx.selectAll('tick').data(ticks).enter();
    tickMarks.append('line')
    .attr('class', 'line')
    .attr('x1', d => this.xscale(d))
    .attr('x2', d => this.xscale(d))
    .attr('y1', this.yscale(0) - 5)
    .attr('y2', this.yscale(0) + 5);
    tickMarks.append('text')
    .attr('class', 'ticklabel')
    .text(d => d)
    .attr('x', d => this.xscale(d))
    .attr('y', this.yscale(0) + 15)
    .attr('text-anchor', 'middle');
}

Cosy.prototype.yAxis = function(ticks, label='') {
    const gy = this._cosy.append('g')
    .attr('class', 'axis y');
    gy.append('line')
    .attr('class', 'line')
    .attr('x1', this.xscale(0))
    .attr('x2', this.xscale(0))
    .attr('y1', this.yscale(this.ymin))
    .attr('y2', this.yscale(this.ymax));
    gy.append('path')
    .attr('class', 'arrow')
    .attr('transform', `translate(${this.xscale(0)},${this.yscale(this.ymax)})`)
    .attr('d', 'M0,0 L4,12 L-4,12 L0,0');
    gy.append('text')
    .attr('class', 'label')
    .text(label)
    .attr('x', this.xscale(0) - 10)
    .attr('y', this.yscale(this.ymax) + 4)
    .attr('text-anchor', 'end');
    const tickMarks = gy.selectAll('tick').data(ticks).enter();
    tickMarks.append('line')
    .attr('class', 'line')
    .attr('x1', this.xscale(0) - 5)
    .attr('x2', this.xscale(0) + 5)
    .attr('y1', d => this.yscale(d))
    .attr('y2', d => this.yscale(d));
    tickMarks.append('text')
    .attr('class', 'ticklabel')
    .text(d => d)
    .attr('x', this.xscale(0) - 10)
    .attr('y', d => this.yscale(d) + 4)
    .attr('text-anchor', 'end');
}

Cosy.prototype.xticks = function(interval) {
    const first = Math.trunc(this.xmin / interval) * interval;
    const last = Math.trunc(this.xmax / interval) * interval;
    return d3.range(first, last + interval, interval);
}

Cosy.prototype.yticks = function(interval) {
    const first = Math.trunc(this.ymin / interval) * interval;
    const last = Math.trunc(this.ymax / interval) * interval;
    return d3.range(first, last + interval, interval);
}

Cosy.prototype.point = function(cx, cy, options) {
    return this._drawing.append('circle')
    .attr('class', 'drawing')
    .attr('cx', this.xscale(cx))
    .attr('cy', this.yscale(cy))
    .attr('r', `${options.r || 3}px`)
    .attr('stroke', 'none')
    .attr('fill', options.color || 'black');
};

Cosy.prototype.line = function(x1, y1, x2, y2, options) {
    return this._drawing.append('line')
    .attr('class', 'drawing')
    .attr('x1', this.xscale(x1))
    .attr('y1', this.yscale(y1))
    .attr('x2', this.xscale(x2))
    .attr('y2', this.yscale(y2))
    .attr('stroke', options.color || 'black')
    .attr('stroke-width', options.strokeWidth || '1px');
};

Cosy.prototype.path = function(points, options) {
    const path = points.map((p, i) => `${i === 0 ? 'M' : 'L'}${this.xscale(p.x)},${this.yscale(p.y)}`).join(' ');
    this._drawing.append('path')
    .attr('class', 'drawing')
    .attr('d', path)
    .attr('fill', 'none')
    .attr('stroke', options.color || 'black')
    .attr('stroke-width', '1px');
};

Cosy.prototype.clear = function() {
    this._drawing.selectAll('.drawing')
    .remove();
};

    export default Cosy;
