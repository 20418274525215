import React from 'react';
import { withTheme } from '@rjsf/core';
import { Theme as AntDTheme } from '@rjsf/antd';
import {resolveAbiFromIpfs} from './utils';

const Form = withTheme(AntDTheme);

function Icon (props = {}) {
    const src = props.src || ('./menu/' + props.title + '.svg')
    const w = props.size || props.width || 20;
    const h = props.size || props.height || 20;
    return <img width={w} height={h} {...props} src={src}></img>
}

const inputSchema = {
    title: '',
    type: 'object',
    properties: {
        ipfs: {
            type: 'string',
            title: 'ipfs hash',
        },
        address: {
            type: 'string',
            title: 'address',
            // required: true,
        },
        name: {
            type: 'string',
            title: 'name',
            // required: true,
        },
        abi: {
            type: 'string',
            title: 'abi',
        }
    }
}

const inputSchemaUi = {
    // "ui:options":  {
    //     orderable: false
    // },
    abi: {
        'ui:widget': 'textarea',
    }
}

function tryParse (v) {
    try {
        return JSON.parse(v);
    } catch(e) {return};
}

function AbiInput (props) {
    const {value, style} = props;
    const [expanded, setExpanded] = React.useState(false);

    const parsedv = tryParse(value);
    const _value = (parsedv || []).map((v => {
        v.abi = JSON.stringify(v.abi);
        return v;
    }));

    const onFormChange = async ({formData}) => {
        if (props.onChange) {
            let data = [];
            let d = {...formData};
            // for (let d of formData) {
                d.abi = tryParse(d.abi);
                d = await resolveAbiFromIpfs(d);
                if (!d.abi) return;
                data.push(d);
            // }
            props.onChange(JSON.stringify(data));
        }
    }

    let formRef;
    const onSubmit = () => props.onSubmit ? props.onSubmit() : null;
    const onError = (v) => console.log(v);

    const _form = expanded ? (
        <Form
            style={{'position': 'absolute', 'top': '0px'}}
            id="AbiInputForm"
            ref={(form) => {formRef = form}}
            schema={inputSchema}
            uiSchema={inputSchemaUi}
            formData={_value[0]}
            onChange={onFormChange}
            onSubmit={onSubmit}
            onError={onError}
        >
            <button className="AbiInputFormSubmit" type="submit">^</button>
        </Form>
    ) : (<></>)

    return (
        <div style={{...style}}>
            {_form}
            <Icon className="btn-icon" title={'eth'} style={{margin: 5, marginTop: 10, background: "#222", borderRadius: "10px", position: "absolute", bottom: 0, left: 0}} onClick={() => setExpanded(!expanded)}/>
        </div>
    );
}

export default AbiInput;
