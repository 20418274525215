const testmd = `
# A demo of \`react-markdown\`

👉 Changes are re-rendered as you type.

👈 Try writing some markdown on the left.

## Overview

* Follows [CommonMark](https://commonmark.org)
* Optionally follows [GitHub Flavored Markdown](https://github.github.com/gfm/)
* Renders actual React elements instead of using \`dangerouslySetInnerHTML\`
* Lets you define your own components (to render \`MyHeading\` instead of \`h1\`)
* Has a lot of plugins

## Table of contents

Here is an example of a plugin in action
([\`remark-toc\`](https://github.com/remarkjs/remark-toc)).
This section is replaced by an actual table of contents.

## React components

::Welcome[nice to meet you]{greeting=Hello name=Rob}
::Button[clickme]{}
::Input[]{placeholder=writeme}

## Syntax highlighting

Here is an example of a plugin to highlight code:
[\`rehype-highlight\`](https://github.com/rehypejs/rehype-highlight).

\`\`\`js
import React from 'react'
import ReactDOM from 'react-dom'
import Markdown from 'react-markdown'
import rehypeHighlight from 'rehype-highlight'

ReactDOM.render(
  <Markdown
    rehypePlugins={[rehypeHighlight]}
  >
    {'# Your markdown here'}
  </Markdown>,
  document.querySelector('#content')
)
\`\`\`


## GitHub flavored markdown (GFM)

For GFM, you can *also* use a plugin:
[\`remark-gfm\`](https://github.com/remarkjs/react-markdown#use).
It adds support for GitHub-specific extensions to the language:
tables, strikethrough, tasklists, and literal URLs.

These features **do not work by default**.
👆 Use the toggle above to add the plugin.

| Feature    | Support              |
| ---------: | :------------------- |
| CommonMark | 100%                 |
| GFM        | 100% w/ \`remark-gfm\` |

~~strikethrough~~

* [ ] task list
* [x] checked item

https://example.com


## Headings

# h1 Heading 8-)
## h2 Heading
### h3 Heading
#### h4 Heading
##### h5 Heading
###### h6 Heading


## Horizontal Rules

___

---

***


## Typographic replacements

Enable typographer option to see result.

(c) (C) (r) (R) (tm) (TM) (p) (P) +-

test.. test... test..... test?..... test!....

!!!!!! ???? ,,  -- ---

"Smartypants, double quotes" and 'single quotes'


## Emphasis

**This is bold text**

__This is bold text__

*This is italic text*

_This is italic text_

~~Strikethrough~~


## Blockquotes


> Blockquotes can also be nested...
>> ...by using additional greater-than signs right next to each other...
> > > ...or with spaces between arrows.


## Lists

Unordered

+ Create a list by starting a line with \`+\`, \`-\`, or \`*\`
+ Sub-lists are made by indenting 2 spaces:
  - Marker character change forces new list start:
    * Ac tristique libero volutpat at
    + Facilisis in pretium nisl aliquet
    - Nulla volutpat aliquam velit
+ Very easy!

Ordered

1. Lorem ipsum dolor sit amet
2. Consectetur adipiscing elit
3. Integer molestie lorem at massa


1. You can use sequential numbers...
1. ...or keep all the numbers as \`1.\`

Start numbering with offset:

57. foo
1. bar


## Code

Inline \`code\`

Indented code

    // Some comments
    line 1 of code
    line 2 of code
    line 3 of code


Block code "fences"

\`\`\`
Sample text here...
\`\`\`

Syntax highlighting

\`\`\` js
var foo = function (bar) {
  return bar++;
};

console.log(foo(5));
\`\`\`

## Tables

| Option | Description |
| ------ | ----------- |
| data   | path to data files to supply the data that will be passed into templates. |
| engine | engine to be used for processing templates. Handlebars is the default. |
| ext    | extension to be used for dest files. |

Right aligned columns

| Option | Description |
| ------:| -----------:|
| data   | path to data files to supply the data that will be passed into templates. |
| engine | engine to be used for processing templates. Handlebars is the default. |
| ext    | extension to be used for dest files. |


## Links

[link text](http://dev.nodeca.com)

[link with title](http://nodeca.github.io/pica/demo/ "title text!")

Autoconverted link https://github.com/nodeca/pica (enable linkify to see)


## Images

![Minion](https://octodex.github.com/images/minion.png)
![Stormtroopocat](https://octodex.github.com/images/stormtroopocat.jpg "The Stormtroopocat")

Like links, Images also have a footnote style syntax

![Alt text][id]

With a reference later in the document defining the URL location:

[id]: https://octodex.github.com/images/dojocat.jpg  "The Dojocat"


## Plugins

The killer feature of \`markdown-it\` is very effective support of
[syntax plugins](https://www.npmjs.org/browse/keyword/markdown-it-plugin).


### [Emojies](https://github.com/markdown-it/markdown-it-emoji)

> Classic markup: :wink: :crush: :cry: :tear: :laughing: :yum:
>
> Shortcuts (emoticons): :-) :-( 8-) ;)

see [how to change output](https://github.com/markdown-it/markdown-it-emoji#change-output) with twemoji.


### [Subscript](https://github.com/markdown-it/markdown-it-sub) / [Superscript](https://github.com/markdown-it/markdown-it-sup)

- 19^th^
- H~2~O


### [\<ins>](https://github.com/markdown-it/markdown-it-ins)

++Inserted text++


### [\<mark>](https://github.com/markdown-it/markdown-it-mark)

==Marked text==


### [Footnotes](https://github.com/markdown-it/markdown-it-footnote)

Footnote 1 link[^first].

Footnote 2 link[^second].

Inline footnote^[Text of inline footnote] definition.

Duplicated footnote reference[^second].

[^first]: Footnote **can have markup**

    and multiple paragraphs.

[^second]: Footnote text.


### [Definition lists](https://github.com/markdown-it/markdown-it-deflist)

Term 1

:   Definition 1
with lazy continuation.

Term 2 with *inline markup*

:   Definition 2

        { some code, part of Definition 2 }

    Third paragraph of definition 2.

_Compact style:_

Term 1
  ~ Definition 1

Term 2
  ~ Definition 2a
  ~ Definition 2b


### [Abbreviations](https://github.com/markdown-it/markdown-it-abbr)

This is HTML abbreviation example.

It converts "HTML", but keep intact partial entries like "xxxHTMLyyy" and so on.

*[HTML]: Hyper Text Markup Language

### [Custom containers](https://github.com/markdown-it/markdown-it-container)

::: warning
*here be dragons*
:::


***


`

const Mark1 = `
:::center
# ENS mark ver 1

:Text[chainId: @chainId]{command="eth-provider-getNetwork"}

:Image[]{src="https://raw.githubusercontent.com/ensdomains/ensdomains-v2/master/src/assets/ensLogo.svg" alt="ensLogo" width=200}

::Input[]{placeholder="ENS Name" label="ensname"}

-----


| Key | Value | Unit |
| -------- | -------- | -------- |
| ETH Address     | :Text[]{command="eth-provider-resolveName" input="ensname" listen="onSubmit"} | bytes |
| ETH Balance     | :Text[]{command="eth-provider-getBalance" input="ensname" listen="onSubmit"} | wei |

:::

`

const Mark2 = `
:::center
# ERC20 mark ver 1

*(only mainnet)*

:Text[chainId: @chainId]{command="eth-provider-getNetwork"}

:Select[]{options=addresses label=selectedContract trigger=onChange}

::Input[]{placeholder="ENS Name" label="ensname"}
::Button[Get Record]{command="eth-provider-resolveName" input="ensname" output="ensaddress"}

:Text[]{label="ensaddress" hidden=true}

-----

::Contract[]{address=selectedContract function="balanceOf" input="ensaddress"}
::Contract[]{address=selectedContract function="name"}
::Contract[]{address=selectedContract function="totalSupply"}


:::


`

const Mark3 = `

:::center
# Blitz Crypto Class - from Contract to “Done!”

*(only ropsten)*

::Input[]{label="productid" hidden=true}

----

::Contract[]{name="VendorRegistration" function="getVendor" input="productid" output="vendor"}

::Contract[]{name="VendorPrices" function="calculateQuantity" input="productid,vendor"}

:::
`

const Mark4 = `
:::center

# Scientific Calculator

*(only ropsten)*

::Input[]{label="number_a_user" placeholder="a:number"}
::Input[]{label="number_b_user" placeholder="b:number"}
::Input[]{label="number_c_user" placeholder="c:number"}

-----

::Input[]{label="answer" placeholder="answer:number"}

------

|  |  |  |  |
| -------- | -------- | -------- | -------- |
| :Contract[]{name="tally" function="add" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="sub" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="mul" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="div" input="number_a,number_b" output="answer_raw" hidden="input,output"} |
| :Contract[]{name="tally" function="mod" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="_addmod" input="number_a,number_b,number_c" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="_mulmod" input="number_a,number_b,number_c" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="abs" input="number_a,number_b" output="answer_raw" hidden="input,output"} |
| :Contract[]{name="tally" function="lt" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="gt" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="eq" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="isZero" input="number_a,number_b" output="answer_raw" hidden="input,output"} |
| :Contract[]{name="tally" function="isOne" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="isNeg" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="isEven" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="isOdd" input="number_a,number_b" output="answer_raw" hidden="input,output"} |
| :Contract[]{name="tally" function="and" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="or" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="xor" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="tally" function="not" input="number_a,number_b" output="answer_raw" hidden="input,output"} |

------

Tally is an arbitrary precision arithmetic library for both the Ethereum Virtual Machine and JavaScript.
This example is testing the Tally smart contract deployed on Ropsten.
The interface for scientific calculator is complex enough to show the flexibility of the Marks Factory.

This is a demo of tally in action:

::Video[]{src="https://www.youtube.com/embed/N8Es2iPLGzY" width="100%" height="400px"}


:::


::Text[]{label="number_a" input="number_a_user" command="dec-to-u128" hidden=true}
::Text[]{label="number_b" input="number_b_user" command="dec-to-u128" hidden=true}
::Text[]{label="number_c" input="number_c_user" command="dec-to-u128" hidden=true}



::Text[]{label="answer_raw" hidden=true}
::Text[]{label="answer" command="u128-to-dec" input="answer_raw" hidden=true}



`

const Mark5 = `
:::center

# Scientific Calculator: quasi v1

*(arbitrary-precision arithmetic library for rational numbers)*

*(only ropsten)*

|  |  |
| -------- | -------- |
| :Input[]{label="number_a_user_n" placeholder="a num: number"} | :Input[]{label="number_b_user_n" placeholder="b num: number"} |
| :Input[]{label="number_a_user_d" placeholder="a denom: number"} | :Input[]{label="number_b_user_d" placeholder="b denom: number"} |

|  |  |  |
| -------- | -------- | -------- |
| :TeX[\\\\text{\\\\(\\\\frac {@number_a_user_n} {@number_a_user_d}\\\\)}]{input="number_a_user_n,number_a_user_d"} | &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  | :TeX[\\\\text{\\\\(\\\\frac {@number_b_user_n} {@number_b_user_d}\\\\)}]{input="number_b_user_n,number_b_user_d"} |

-----


::TeX[\\\\text{\\\\(\\\\frac {@num} {@denom}\\\\)}]{label="answer"}

------

|  |  |  |  |
| -------- | -------- | -------- | -------- |
| :Contract[]{name="quasi" function="add" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="sub" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="mul" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="div" input="number_a,number_b" output="answer_raw" hidden="input,output"} |
| :Contract[]{name="quasi" function="lt" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="gt" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="eq" input="number_a,number_b" output="answer_raw" hidden="input,output"} | |
| :Contract[]{name="quasi" function="normalize" input="number_a,number_b" output="answer_raw" hidden="input,output"} | :Contract[]{name="quasi" function="asTally" input="number_a,number_b" output="answer_raw" hidden="input,output"} | | |

------

Quasi is an arbitrary-precision arithmetic library for rational numbers, for both the Ethereum Virtual Machine and JavaScript.
This example is testing the Quasi smart contract deployed on Ropsten.
The interface for scientific calculator is complex enough to show the flexibility of the Marks Factory.

This is a demo of quasi in action:

::Video[]{src="https://www.youtube.com/embed/GrJb4ZUUVWw" width="100%" height="400px"}

:::


::Text[]{label="number_a" input="number_a_user_n,number_a_user_d" command="dec-to-quasi" hidden=true}
::Text[]{label="number_b" input="number_b_user_n,number_b_user_d" command="dec-to-quasi" hidden=true}
::Text[]{label="number_c" input="number_c_user_n,number_c_user_d" command="dec-to-quasi" hidden=true}



::Text[]{label="answer_raw" hidden=true}
::Text[]{label="answer" command="quasi-to-dec" input="answer_raw" hidden=true}


`

const Mark6 = `

:::center

# Payment v1
## Request/Fulfill

*(connect to wallet)*

:Text[chainId: @chainId]{command="eth-provider-getNetwork"}

::Button[qr]{icon=true command="qr-generate-ours" size=30}

-------
:Text[]{label="amount_wei" input="amount" command="eth-utils-parseUnits"} WEI


|  |  |
| -------- | -------- |
| :Input[]{label="amount" placeholder="amount: ETH"} eth | :Input[]{label="gasLimit" placeholder="gas: unit"} gas limit |
| :Input[]{label="receiver" placeholder="receiver: address/ens"} receiver | :Input[]{label="gasPrice" placeholder="price: GWEI"} gas price |

::Input[]{label="reason" placeholder="payment reason"}

------
::Text[Transaction hash: @transactionHash]{label="receipt"}
::Text[]{label="receipt"}

::Button[fingerprint]{icon=true command="eth-pay" input="receiver,amount_wei,gasLimit,gasPrice" output="receipt" size=30}

-------
:::

## Details

- **eth** in this field input the amount to be paid in ETH.
- **receiver** the Ethereum address \`0x..\` or the ENS name of the receiver.
- **gas limit** maximum amount of gas units to be spent in the execution of the transaction
- **gas price** gas price in GWEI. The total cost for sending the transaction is :Math[\\\\text{\\\\({gas limit}\\\\times {gas price}\\\\)}]{}
- **reason** reason for payment
- :Button[fingerprint]{icon=true} - click for triggering payment
- :Button[qr]{icon=true} - get a QRcode and URL with preset inputs


:::center

::Video[]{src="https://www.youtube.com/embed/vVeI_d-Uj8M" width="100%" height="400px"}

:::


`

const Mark7 = `:::center
# Marks Factory Tutorial v1
:::

## Intro

This is the Marks Factory. \`v1\` means \`version 1\`.
Marks Factory is a dApp (decentralized app) player and an IDE (Integrated development environment) for dApps.

Marks are markdown apps with access to decentralized web tools. This tutorial is a mark.

### Known Issues
- connecting to the IPFS network and loading marks is sometimes slow: refresh the page.

### Top Toolbar
:Button[more]{icon=true} load a recommended mark
:Button[edit]{icon=true}  enter "edit" mode.
:Button[play]{icon=true}  enter "play" mode. This is how your mark is presented to the user.
:Button[export]{icon=true} export your mark to IPFS. If your mark uses contract ABIs and addresses from your workspace, they will also be exported. You will see a QRcode image and if you click on it, the URL will be copied to the clipboard. The URL is of the form \`<BASE_URL>/?ipfs=<IPFS_HASH>&input=<PREDEFINED_INPUT>\`. You can preset form inputs by filling them in and then exporting.
:Button[eth]{icon=true} Load a contract function form, from the ABIs and addresses saved in the workspace
:Button[component]{icon=true} Load a user interface component or command
:Button[bold]{icon=true} - :Button[deindent]{icon=true} markdown syntax helpers. See https://guides.github.com/features/mastering-markdown/

### Bottom Workspace

Add contract ABI & address information by:
- clicking on the bottom left :Button[more]{icon=true} -> Add item -> filling the form; if you fill in an IPFS hash from a contract saved in the Solidity compiler format, the abi will be loaded automatically -> click on the bottom right :Button[^]{}
- pasting JSON data in the bottom text area, of the form:
\`\`\`
[{
    "ipfs": "QmP5rC8m7DsZwmRjFr7PESKe9e9o7p7VVNP62jdBbiCGbQ",
    "name": "WETH",
    "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
    "abi": []
}]
\`\`\`

## Add your mark to the menu recommendations

Make a Pull Request to https://github.com/loredanacirstea/recommendations/blob/main/marks/mainMenu.json and add your own mark under an existing category or propose a new category.

### Requirements
- your mark will have the GNU General Public License v3.0 license.
- mark title has the form: \`<TITLE> v<VERSION>\`, e.g. \`Mark Title v1\`
- mark contains a video tutorial of how to use the mark
- add a \`:Link[created by 0x<ETH_ADDRESS>]{src=<DONATION_MARK>}\` at the bottom of your mark, for eventual donations, directing to the Payment mark preset with your data (example at the bottom of this mark). Fill in your data in :Link[Payment Request]{src="/?ipfs=QmfFPi6EutBU2kdQExT57qV4aWtRx7KyrXnVnvQBuEme6k"} and export, to get the link
- the mark's "label" is the same as its title
- export your mark to IPFS first and use the 46 character hash as the mark's value id (e.g. \`QmadhiGumnFNeHf8pJge6A2sdunVNXH6VnLuFFvzsAbieC\`)

### Info
- if your mark has been approved, updating the mark to a new version will be prioritized. E.g. if others, at a later date will propose a mark with the same functionality, it will not be approved unless exceedingly better.
- if a similar mark already exists, make a PR only if your mark brings significant improvements.

### Resources

- Marks Factory playlist https://www.youtube.com/playlist?list=PL323JufuD9JBUwKSPILGKBaI5JPyAVapn

-----

:::center


::Video[]{src="https://www.youtube.com/embed/6QkiuUSg7L8" width="100%" height="400px"}

The following is the link for supporting/promoting both Marks Factory and this tutorial:

:Link[created by 0x833D4c383Bef3163d447F90DA78f97379bDcC04d]{src="/?ipfs=QmfFPi6EutBU2kdQExT57qV4aWtRx7KyrXnVnvQBuEme6k&input=%7B%22amount_wei%22%3A%7B%22_hex%22%3A%220x071afd498d0000%22%2C%22_isBigNumber%22%3Atrue%7D%2C%22somethingnotexisting%22%3A%7B%22name%22%3A%22ropsten%22%2C%22chainId%22%3A3%2C%22ensAddress%22%3A%220x00000000000C2E074eC69A0dFb2997BA6C7d2e1e%22%7D%2C%22amount%22%3A%220.002%22%2C%22gasLimit%22%3A%2235000%22%2C%22gasPrice%22%3A%2215%22%2C%22receiver%22%3A%220x833D4c383Bef3163d447F90DA78f97379bDcC04d%22%2C%22reason%22%3A%22donation%20for%20creating%20a%20useful%20mark%22%7D"}

--------


:::



`

const Mark10 = `

::Input[]{label="partial1"}
::Input[]{label="partial2"}

::Text[@partial1 and @partial2]{input="partial1,partial2"}

::Link[alink]{input="partial1,partial2" src="https://ipfs.io/ipfs/@partial1/@partial2"}

`

const Mark13 = `
# Title

::Editor[]{label=sometext width="50%" height=200}
::Markdown[]{label=sometext width="50%" height=200}

`

const Mark14 = `
# Title

<BComponent:((fn* (props)
(let* (
  inputState "ola"
  gInputStateSetter (get-state-setter "inputState_B")
  render (fn* (props state stateChangers)
    (let* (
        inputSetter (fn* (v) (do
          (gInputStateSetter v)
          ((get stateChangers "inputState") v)
        ))
      )
      (react-div {} (list
        (react-input {
          "key" 1
          "placeholder" "hello"
          "value" (get state "inputState")
          "onChange" inputSetter
        })
        (react-select {
          "key" 2
          "options" [{"label" "hello" "value" "hello"} {"label" "ola" "value" "ola"}]
          "value" (get state "inputState")
          "onChange" inputSetter
        })
      ))
    )
  )
)
  (react props {"inputState" inputState} {"render" render})
)
) {})
>


::Text[]{label="inputState_B"}

<atext:(watch
  ["inputState_B"]
  (fn* () (react-text {} (str "This is text: " (get-state "inputState_B") )) )
)>

<abutton:(react-button
  {"onClick" (fn* () (log "button"))}
  "click"
)
>

:Text[chainId: @chainId]{command="eth-provider-getNetwork"}

`

const drawerEx = `

<inistate:(set-state "drawerVisible" true)>

<declan:(react-div {} (react-button {
  "onClick" (fn* () (set-state "drawerVisible"
    (if (true? (get-state "drawerVisible")) false true)
  ))
} "drawer"))>

<drawer:(watch
  ["drawerVisible"]
  (fn* ()
    (react-drawer {
      "title" "Basic Drawer"
      "mask" false
      "placement" "right"
      "closable" true
      "onClose" (fn* () (set-state "drawerVisible" false))
      "visible" (get-state "drawerVisible")
      "key" "right"
    })
  )
)>
`

const requireExample = `

<requireSmth:(require "d3")>

<smth:(watch
  ["lib-d3"]
  (fn* ()
    (react-text {"value" (lib-d3 "count" [3 4 6])} )
  )
  true
)>

`;

const d3example = `

<smth:(watch
  ["lib-d3", "votingEvents"]
  (fn* ()
    (let* (
        votingEvents (get-state "votingEvents")
        data (map
          (fn* (v) (let* (
              args (get v "args")
            )
            {"x" (bn-toNumber (get args "x")) "y" (bn-toNumber (get args "y")) }
          ))
          votingEvents
        )

        componentDidMount (fn* (props state stateChangers)
          (lib-d3 (list
            ["select" ["#votingGraph"]]
            ["append" ["svg"]]
            ["attr" ["width" 300]]
            ["attr" ["height" 300]]
            ["append" ["g"]]
            ["attr" ["transform" "translate(10,10)"]]

            ["append" ["path"]]
            ["datum" [data]]
            ["attr" ["fill" "none"]]
            ["attr" ["stroke" "steelblue"]]
            ["attr" ["stroke-width", 1.5]]
            ["attr" ["d" (lib-d3 (list
                ["line" []]
                ;["x" [ (js-eval "function fn() { return 5}") ]]
                ;["y" [ (js-eval "function fn() { return 10}") ]]

                ["x" [ (fn* (v i arr) 5 (get v "x")) ]]
                ["y" [ (fn* (v i arr) 10 (get v "y")) ]]
            ))]]
          ))
        )

        render (fn* (props state stateChangers)
          (react-div {"id" "votingGraph"})
        )
      )
      (react {} {} {"render" render "componentDidMount" componentDidMount})
    )
  )
  true
)>

`


const defaultMark = Mark7;

export {Mark1, Mark2, Mark3, Mark4, Mark5, Mark6, Mark7, defaultMark};
