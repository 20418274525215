import Ipfs from 'ipfs-core';
import { useEffect, useState } from 'react';

let ipfs = null
window.Ipfs = Ipfs;
export async function startIpfs () {
    let _error;
    if (ipfs) {
        console.log('IPFS already started')
    } else if (window.ipfs && window.ipfs.enable) {
        console.log('Found window.ipfs')
        ipfs = await window.ipfs.enable({ commands: ['id'] })
    } else if (window.crypto.subtle) {
        try {
            console.time('IPFS Started')
            ipfs = await Ipfs.create()
            console.timeEnd('IPFS Started')
        } catch (error) {
            console.error('IPFS init error:', error)
            ipfs = null
            _error = error;
        }
        return {ready: Boolean(ipfs), ipfs, error: _error};
    }

    return {ready: true, ipfs: {}, error: 'No WebCrypto'};
}

export function ipfsCleanup () {
    if (ipfs && ipfs.stop) {
        console.log('Stopping IPFS')
        ipfs.stop().catch(err => console.error(err))
        ipfs = null
    }
}

export function useIpfsFactory () {
    const [isIpfsReady, setIpfsReady] = useState(Boolean(ipfs))
    const [ipfsInitError, setIpfsInitError] = useState(null)

    useEffect(() => {
        const {error, ready} = startIpfs();
        if (error) setIpfsInitError(error);
        setIpfsReady(ready);

        return function cleanup () {
            ipfsCleanup();
            setIpfsReady(false);
        }
}, [])

  return { ipfs, isIpfsReady, ipfsInitError }
}
