export function extractLabelExpression (value) {
    const colonndx = value.indexOf(':');
    const label = value.slice(0, colonndx).trim();
    const expression = value.slice(colonndx + 1).trim();
    return [label, expression];
}

export function extractNodes (value) {
    if (!value) return [{}, ''];
    let nodes = {};
    let newvalue = '';
    let openIndex = 0;
    let closeIndex = 0;
    let opened = 0;
    let closed = 0;
    for (let i = 0; i < value.length; i++) {
        const char = value[i];
        if (char === '<') {
            opened ++;
            if (opened === 1) openIndex = i + 1;
        }
        else if (char === '>') {
            closed ++;
        }
        if (opened > 0 && opened === closed) {
            const _value = value.slice(openIndex, i);
            const [label, expression] = extractLabelExpression(_value);

            const node = {
                value: _value,
                label,
                expression,
                start: openIndex,
                end: i - 1,
            };
            nodes[label] = node;

            newvalue += value.slice(closeIndex, openIndex - 1);
            // newvalue += `|${label}|`;
            newvalue += `::${label}[]{}`;
            opened = 0;
            closed = 0;
            closeIndex = i + 1;
        }
    }
    newvalue += value.slice(closeIndex);
    if (!newvalue) newvalue = value;
    return [nodes, newvalue];
}

export function forceNewLine (text) {
    const parts = text.split('\n');
    // "a\nb\n\nc\nd".split('\n') -> ["a", "b", "", "c", "d"]
    const newparts = parts.map((part, i) => {
        if (part === '' || parts[i + 1] === '' || !parts[i + 1]) return part;
        return part + '\\';
    });
    return newparts.join('\n');
}
