import {tally} from '@ark-us/tally';
import BN from 'bn.js';
import {ethers} from 'ethers';

const BigNumber = ethers.BigNumber;

async function init () {
    const extensions = {
        label: '',
        items: [
            {
                label: 'dec-to-u128',
                value: _decToArray128,
            },
            {
                label: 'u128-to-dec',
                value: u128ToDec,
            },
            {
                label: 'dec-to-quasi',
                value: (a, b) => {
                    return {num: _decToArray128(a), denom: _decToArray128(b)}
                },
            },
            {
                label: 'quasi-to-dec',
                value: (q) => {
                    let {num, denom} = q;
                    if (!num) [num, denom] = q;
                    return {num: u128ToDec(num), denom: u128ToDec(denom)}
                },
            }
        ]
    }
    return {extensions};
}

export default init;

function _decToArray128 (value) {
    return decToArray128(value, (v => {
        return BigNumber.from(v);
    }));
}

export function u8ToHex (a8, size) {
    size = size || a8.byteLength;
    const value = a8.slice(0, size).reverse()
    const arr = [...(new Array(size - value.length).fill(0)), ...value]
      .map((v) => v.toString(16).padStart(2, '0'))
      .join('')
      .padStart(64, '0');
    return arr;
}

export function decToU8 (value) {
    const bn = new BN(value, 10);
    return bn.toArray('le');
}

export function hexToDec (v) {
    return  BigNumber.from(v).toString();
}

export function decToArray128 (value, hexToBN) {
    let u8arr = decToU8(value);
    let len = u8arr.length;
    const r = len % 16;
    if (r > 0) {
        const diff = 16 - r;
        u8arr = [...u8arr, ...new Array(diff).fill(0)];

    }
    const u128arr = [];
    for (let i = 0 ; i < u8arr.length / 16; i++) {
        const slice = u8arr.slice(i * 16, i * 16 + 16)
        let num = u8ToHex(new Uint8Array(slice));
        num = hexToBN('0x' + num);
        u128arr.push(num);
    }
    return u128arr;
}

export function u128ToDec (value) {
    let u8arr = [];
    for (let i = 0; i < value.length; i++) {
      const t = new BN(value[i]._hex.slice(2), 16).toArray('le');
      u8arr = [...u8arr, ...t]
    }
    const v = u8ToHex(new Uint8Array(u8arr));
    return hexToDec('0x' + v);
}
