import {getAllFuncs} from './utils';

async function init () {
    const extensions = {
        label: 'document',
        items: [
            {
                label: 'getElementsByClassName',
                value: (className) => {
                    const v = document.getElementsByClassName(className);
                    return v;
                },
            },
            {
                label: 'getElementByClassName',
                value: (className, index = 0) => {
                    const v = document.getElementsByClassName(className);
                    if (v.length > index) return v[index];
                    return null;
                },
            },
            {
                label: 'getElementById',
                value: (id) => {
                    const v = document.getElementById(id);
                    return v;
                },
            },
            {
                label: 'getBoundingClientRect',
                value: (elem) => {
                    const value = elem.getBoundingClientRect();
                    return {
                        x: value.x,
                        y: value.y,
                        width: value.width,
                        height: value.height,
                        bottom: value.bottom,
                        left: value.left,
                        right: value.right,
                        top: value.top,
                    }
                },
            },
            {
                label: 'create-element',
                value: (type, properties = {}, parentSelector) => {
                    let parent = document.body;
                    let elem;
                    if (properties.id) elem = document.getElementById(properties.id);
                    if (!elem) elem = document.createElement(type);
                    for (let key in properties) {
                        elem[key] = properties[key];
                    }
                    if (parentSelector && typeof parentSelector === 'string') {
                        parent = document.querySelector(parentSelector);
                    }
                    parent.appendChild(elem);
                },
            },
            {
                label: 'apply',
                value: (elem, ...args) => {
                    const method = args.shift();
                    const value = elem[method](...args);
                    return value;
                },
            },
            {
                label: 'add-class-to-query',
                value: (query, classesStr = []) => {
                    const elems = document.querySelectorAll(query);
                    const _classes = typeof classesStr === 'string' ? classesStr.split(' ') : classesStr;
                    console.log('add-class', query, _classes, elems)
                    for (let elem of elems) {
                        for (let cl of _classes) {
                            elem.classList.add(cl);
                        }
                    }
                },
            },
            {
                label: 'remove-class-from-query',
                value: (query, classesToRemove = []) => {
                    const elems = document.querySelectorAll(query);
                    classesToRemove = typeof classesToRemove === 'string' ? classesToRemove.split(' ') : classesToRemove;
                    console.log('remove-class', query, classesToRemove, elems)
                    for (let elem of elems) {
                        for (let cl of classesToRemove) {
                            elem.classList.remove(cl);
                        }
                    }
                },
            },
        ]
    }
    return {extensions};
}

export default init;
