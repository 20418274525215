import { resolveAbiFromIpfs } from './components/schema/utils';
import { locationParams } from './browser';
import { REGISTRY_URL } from './config';

async function init () {
    const extensions = {
        label: 'marks',
        items: [
            {
                label: 'url-ipfs',
                value: (ipfsHash, input) => {
                    console.log('url-ipfs', ipfsHash, input);
                    const ipfsh = typeof ipfsHash === 'string' ? ipfsHash : ipfsHash.path;
                    let query = `?ipfs=${ipfsh}`;
                    if (input && typeof input !== 'string') {
                        input = JSON.stringify(input);
                    }
                    if (input) {
                        input = encodeURIComponent(input);
                        query += `&input=${input}`;
                    }
                    return window.location.origin + window.location.pathname + query;
                }
            },
            {
                label: 'url-raw',
                value: ({chain, contracts, content} = {}) => {
                    if (typeof contracts !== 'string') {
                        contracts = JSON.stringify(contracts);
                    }
                    contracts = encodeURIComponent(contracts);
                    content = encodeURIComponent(content);

                    return window.location.origin + window.location.pathname + `?chain=${chain}&contracts=${contracts}&content=${content}`;
                }
            },
            {
                label: 'url-input-decode',
                value: (inputString) => {
                    let input = decodeURIComponent(inputString);
                    try {
                        input = JSON.parse(input);
                    } catch (e) {
                        input = {};
                    }
                    return input;
                }
            },
            {
                label: 'url-input-encode',
                value: (input) => {
                    return encodeURIComponent(JSON.stringify(input));
                }
            },
            {
                label: 'contracts-hydrate',
                value: contractsHydrate,
            },
            {
                label: 'go',
                value: (value) => {
                    window.open(`/?${value.protocol}=${value.id}&input=`)
                }
            },
            {
                label: 'menu',
                value: async () => {
                    let file = 'mainMenu.json';
                    const params = locationParams();
                    if (params && params.l) {
                        file = `mainMenu-${params.l}.json`;
                    }
                    const url = REGISTRY_URL + file;
                    let obj;
                    try {
                        const response = await fetch(url);
                        obj = await response.json();
                    } catch(e) {
                        obj = {};
                        console.error(e);
                    }
                    return obj;
                }
            }
        ]
    }
    return {extensions};
}

export default init;

export const contractsHydrate = async (contractdata) => {
    for (let i = 0; i < contractdata.length; i++) {
        contractdata[i] = await resolveAbiFromIpfs(contractdata[i]);
    }
    return contractdata;
}

