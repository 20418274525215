import taylorv1 from '@pipeos/taylor';

async function init (provider, signer) {
    const taylorJs = await taylorv1.malBackend.getBackend(null, provider, signer);
    let taylorEvm;

    async function initTaylorEvm () {
        try {
            taylorEvm = await taylorv1.default(provider, signer);
        } catch (e) {
            console.error('Could not initiate taylor EVM', e);
        }
        if (!taylorEvm) taylorEvm = await taylorv1.deploy(provider, signer);
    }

    async function safeEvmEval (...args) {
        if (!taylorEvm) await initTaylorEvm();
        try {
            const result = await taylorEvm.call(...args);
            return result;
        } catch(e) {
            return e.message;
        }
    }

    async function safeJsEval (...args) {
        try {
            const result = await taylorJs.call(...args);
            return result;
        } catch(e) {
            return e.message;
        }
    }

    const extensions = {
        label: 'taylor0',
        items: [
            {
                label: 'evm-eval',
                value: safeEvmEval,
            },
            {
                label: 'js-eval',
                value: safeJsEval,
            }
        ]
    }
    return {extensions};
}

export default init;
