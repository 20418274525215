import * as SolidityCompiler from '@remix-project/remix-solidity';

export const baseSolcUrl = 'https://solc-bin.ethereum.org/wasm'

let compiler;
let compilerLoaded;

function handleImportCall (fileurl, cb) {
    console.log('handleImportCall', fileurl, cb);
}

function loadCompiler (compilerVersion) {
    if (!compiler) compiler = new SolidityCompiler.Compiler(handleImportCall);

    const url = 'https://solc-bin.ethereum.org/wasm/soljson-v0.8.3+commit.8d00100c.js';

    return new Promise((resolve, reject) => {
        if (compiler.state && compiler.state.worker) {
            resolve(compilerLoaded);
            return;
        }

        compiler.event.register('compilerLoaded', (...args) => {
            compilerLoaded = args[0];
            resolve(...args);
        });
        compiler.loadWorker(url);
    });
}

async function compile (source, language, compilerVersion, target = 'ir') {
    await loadCompiler(compilerVersion);

    console.log('language', language);
    compiler.state.language = language || 'Solidity';
    return new Promise((resolve, reject) => {
        compiler.event.register('compilationFinished', (...args) => {
            resolve(args);
        });
        compiler.compile(source, target);
    });
}

export default compile;
