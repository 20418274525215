import {toc} from 'mdast-util-toc';

const tocPlugin = (ontoc) => (options) => {
  var settings = options || {tight: true}

  return transformer

  function transformer(node) {
    var result = toc(
      node,
      settings,
    )
    if (!result || !result.map) return;

    const children = [].concat(result.map);
    ontoc({...node, children});
  }
}

export default tocPlugin;
