import React, {useEffect} from 'react';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import breaks from 'remark-breaks';
import directive from 'remark-directive';
import math from 'remark-math';
import katex from 'rehype-katex';
import _rtoc from './components/markdown/toc';
import rslug from 'remark-slug';
import {toMarkdown} from 'mdast-util-to-markdown';
import {extractNodes} from './components/markdown/extractTaylor';
import {visit} from "unist-util-visit";
import safestringify from 'safe-stable-stringify';
import {isReact} from '@ark-us/taylor-react';
import {AbiFormFunction} from './components/schema/abiform';
import {DEFAULT_ABIS, DEFAULT_ADDRESSES, DEFAULT_TAGS, DEFAULT_JURISDICTION, DEFAULT_CATEGORIES, DEFAULT_SKILLS} from './data';
import {locationParams} from './browser';
import {ipfsSet, parseCID} from './ipfs';
import {valueToHex} from './eth';
import {registerMarkInstance} from './communication';
import {STORAGE_MENU, STORAGE_CODE, STORAGE_MANUAL} from './config';
import ReactJson from '@ark-us/react-json-view';
import {
    Select, DatePicker, TreeSelect, Input as AntdInput,
    Tabs, Drawer, List, Table, Pagination, Radio,
    Card, Col, Row, Tag,
} from 'antd';
import DynamicForm from './components/DynamicForm';
import ModalComponent from './components/Modal';
import 'katex/dist/katex.min.css';
import TeX from '@matejmazur/react-katex';
import Map, {MapView} from './components/map/map';
import moment from 'moment';
import {ethers} from 'ethers';
import BN from 'bn.js';
import {Editor} from './editor';
import {generateQrCode} from './qrcode';
import {safeChoice, makeSync} from './utils';
import VirtualTable from './components/VirtualTable';
import _require from './require';
import {utils as jsvmUtils} from 'ewasm-jsvm';
import * as graphs from './graphs';
import {storage} from './storage';
import {splitPresentation, scaleSlide} from './presentation';

const {toBN} = jsvmUtils;

const { TextArea: AntdTextArea } = AntdInput;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;
const { TabPane } = Tabs;
const { Meta } = Card;

function reactMarkdownRemarkDirective() {
    return (tree) => {
      visit(
        tree,
        ["textDirective", "leafDirective", "containerDirective"],
        (node) => {
          node.data = {
            hName: node.name,
            hProperties: node.attributes,
            ...node.data
          };
          return node;
        }
      );
    };
}

async function init (taylor, globalCommands) {

const registry = new EventTarget();
const extensions = {
    label: 'md-viewer',
    items: [
        {
            label: '',
            value: MarkdownViewer,
        },
        {
            label: 'contracts',
            value: getInWorkContracts,
        },
        {
            label: 'input',
            value: getInWorkInput,
        },
        {
            label: 'content',
            value: () => {
                return storage.getItem(STORAGE_CODE);
            },
        },
        {
            label: 'url',
            value: getUrl,
        },
    ]
}

let _unsubscribeList = [];
function unsubscribeAdd(fn) {
    _unsubscribeList.push(fn);
}
function unsubscribeResolve() {
    // console.log('---unsubscribeResolve');
    _unsubscribeList.map(fn => fn());
    _unsubscribeList = [];
}

const _eventSubs = {};
// _eventSubs[contractName]

const setStateNoEvent = (label, value) => {
    globalState[label] = value;
    taylor.global.set(label, value);
}

const setState = (label, value, eventName = 'onChange') => {
    setStateNoEvent(label, value);
    let event = new CustomEvent(label, {detail: eventName});
    registry.dispatchEvent(event);
}

const setStates = (states) => {
    for (let state of states) {
        setStateNoEvent(...state);
    }
    for (let state of states) {
        const [label, _, eventName] = state;
        let event = new CustomEvent(label, {detail: eventName});
        registry.dispatchEvent(event);
    }
}

const getState = (label) => {
    if (!label) return null;
    return safeChoice(globalState[label], usedInput[label]);
}

function parseCoords (val) {
    return typeof val === 'string' ? val.split(',').map(v => parseFloat(v.trim())) : val;
}

taylor.eval(`(def! do-and-render (fn* (actions component)
    component
))`);

taylor.eval(`(def! get-state-setter (fn* (label)
    (fn* (value) (set-state label value))
))`);
taylor.extendfn('stringify', safestringify);
taylor.extendfn('set-state', setState);
taylor.extendfn('set-state-raw', setStateNoEvent);
taylor.extendfn('get-state', getState);
taylor.extendfn('set-states', setStates);
taylor.extendfn('get-contract-address', (label) => {
    const contract = getState(label);
    if (!contract) return null;
    return contract.address;
})
taylor.extendfn('watch', (labels, componentfn, alltrue) => {
    function Component () {
        const values = useListeners({labels});
        const [component, setC] = React.useState(<span></span>);
        React.useEffect(() => {
            async function setComponent () {
                if (typeof componentfn !== 'function') return;
                let runfn = alltrue ? Object.values(values).every(v => typeof v !== 'undefined' && v !== null) : true;

                if (runfn) {
                    let c;
                    try {
                        c = await componentfn();
                        if (c && isReact(c)) setC(c);
                    } catch (e) {
                        setC(<p>{`watch ${JSON.stringify(labels)} error:`, e.message}</p>);
                    }
                }
            }
            setComponent();

        }, [values]);

        const _isReact = component ? isReact(component) : false;
        if (!_isReact) {
            return (<></>);
        }
        return <ErrorBoundary>{component}</ErrorBoundary>;
    }
    try {
        return <Component />;
    } catch (e) {
        console.debug('watch', e);
        return <p>{e.message}</p>;
    }
});
taylor.extendfn('require', async (...libraries) => {
  console.log('require', libraries);
  for (let library of libraries) {
    if (typeof library === 'string') library = [library];
    let [name, alias] = library;
    alias = alias || name;
    console.log(name, alias);
    const instance = await _require(name, alias);
    const label = 'lib-' + alias;
    taylor.extendfn(label, accessRequiredLibrary(instance));
    // don't use setState, otherwise it will rewrite the taylor function
    globalState[label] = true;
    let event = new CustomEvent(label, {detail: {}});
    registry.dispatchEvent(event);
  }
});
const accessRequiredLibrary = (instance) => {
    function applyField (inst, fpath) {
        const path = fpath.split('.');
        let _instance = inst;
        while (path.length > 0) {
            _instance = _instance[path.pop()];
        }
        return _instance;
    }
    async function resolveCall (fpath, ...args) {
        if (!fpath || typeof fpath !== 'string') {
            console.debug(fpath, args);
            return 'Expected function name as string';
        }
        let _instance = applyField(instance, fpath);
        // _instance = _instance.bind(instance);
        try {
            const result = await _instance(...args);
            return result;
        } catch (e) {
            console.debug(e);
            return e.message;
        }
    }
    return async (fpath, ...args) => {
        console.log('resolveCall', fpath, args);
        // simple call
        if (typeof fpath === 'string') return resolveCall(fpath, ...args);
        if (fpath instanceof Array) {
            // we do chaining
            let _instance = await resolveCall(fpath[0][0], ...fpath[0][1]);
            for (let obj of fpath.slice(1)) {
                const bindobj = _instance;
                _instance = applyField(_instance, obj[0]);
                _instance = _instance.bind(bindobj);

                const _args = obj[1].map(v => {
                    if (typeof v !== 'function') return v;
                    return (...fnargs) => {
                        // console.log('fnargs', fnargs);
                        const result = makeSync(() => v(...fnargs), 1000);
                        // console.log('result', result);
                        return result;
                    }
                });

                // console.log(obj[0], ..._args);
                _instance = await _instance(..._args);
                // _instance = await _instance[obj[0]](...obj[1]);
            }
            return _instance;
        }
    }
}

taylor.extendfn('json-assoc', (...args) => args.pop());
taylor.extendfn('copy', (origin, target) => {
    setState(target, getState(origin));
});
taylor.extendfn('bn-toNumber', (n, ...args) => {
    let sign = 1;
    let value;
    if (n && n.hex) n._hex = n.hex;
    if (n && n._hex && n._hex[0] === '-') {
        sign = -1;
        n._hex = n._hex.slice(1);
        n.hex = n._hex;
    }

    try {value = toBN(n || 0).toNumber(...args);}
    catch(e) {console.debug(e); return null;}
    return value * sign;
});
taylor.extendfn('bn-toString', (n, base = 10, ...args) => {
    let value;
    try {
        value = toBN(n || 0);
        return value.toString(base, ...args);
    }
    catch(e) {return e.message;}
});
taylor.extendfn('qr-generate-ours', async () => {
    const url = await getUrl();
    generateQrCode('qrcodeCanvas', url);
});
taylor.extendfn('marks-url-input-encode', (input) => {
    return encodeURIComponent(JSON.stringify(input));
});
taylor.extendfn('graphs-voting-continuous', (input) => {
    return graphs.voting.continuous(input);
});
taylor.extendfn('graphs-fn-accumulate', (...args) => {
    return graphs.accumulateVotes(...args);
});
taylor.extendfn('marks-export', exportMark);
taylor.extendfn('marks-export-contract', exportContract);
taylor.extendfn('ipfs-id-core', (id) => {
    if (!id) return;
    if (typeof id === 'string') id = {cid: parseCID(id)};
    if (typeof id !== 'object') return id;
    const coreid = id.cid.multihash.slice(2);
    const _id = '0x' + u8tohex(coreid);
    return _id;
});

taylor.extendfn('id-build', (jurisdiction, datevalue, tags, coordinates, finalDataHash) => {
    if (typeof finalDataHash === 'string') finalDataHash = {cid: parseCID(finalDataHash)};
    let datevalueStart = datevalue instanceof Array ? datevalue[0] : datevalue;
    const prefix = finalDataHash.cid.multihash.slice(0, 2);
    coordinates = parseCoords(coordinates);
    const lat = parseInt(coordinates[0] * Math.pow(10, 15));
    const lng = parseInt(coordinates[1] * Math.pow(10, 15));
    const _jurisdiction = intToHexPad(jurisdiction, 1)
    const _datevalue = intToHexPad(datevalueStart, 8)
    const _tags = intToHexPad(tags, 2)
    const _lat = intToHexPad(lat, 8)
    const _lng = intToHexPad(lng, 8)
    const _prefix = u8tohex(prefix); // 2 // 29 -> 3
    let id = _jurisdiction + _datevalue + _tags + _lat + _lng + _prefix;
    id = id.padEnd(64, '0');
    return '0x' + id;
});
// 0x02179bd8c1b233e00a36cc19bab00000011c37937e08 00012200000000000000
taylor.extendfn('id-from-event', (contentHash, tagId) => {
    const prefix = tagId.toHexString().slice(2).slice(53, 57);
    const ipfsHash = prefix + contentHash.toHexString().slice(2);
    const bin = hextou8(ipfsHash);
    const cid = parseCID(bin);
    return cid.toString();
});
const ethContractEvents = async (contractName, eventName, filter = {}, fromBlock, callback) => {
    let contract = globalState[contractName];
    usedContracts[contractName] = globalState[contractName];
    if (!contract) return [];
    const {address, abi} = contract;
    if (!address || !abi) return [];
    filter = filter || {};
    const instance = globalCommands['eth-Contract'](address, abi);
    let events = [];
    try {
        events = await instance.queryFilter(eventName, fromBlock);
    } catch (e) {
        console.debug(e);
        return [];
    }

    console.log('---events0', eventName, events.length, filter);

    let keys = Object.keys(filter);


    function prepareEvents (events) {
        // console.log('keys', keys)
        if (keys.length > 0) {
            events = events.filter((ev => {
                const {args} = ev;
                const notPart = keys.some((key) => {
                    if (!args[key]) return true;

                    if (args[key] instanceof ethers.BigNumber) {
                        const yes = args[key].toHexString() === filter[key] || args[key].toNumber() === filter[key] || args[key] === filter[key];
                        return !yes;
                    }

                    return args[key] !== filter[key];

                    // const v = args[key] instanceof ethers.BigNumber ? args[key].toHexString() : args[key];

                    // let val = filter[key];
                    // console.log(v, val);

                    // // return v !== filter[key];
                    // return v != val;
                });
                return !notPart;
            }))
        }
        // console.log('----prepareEvents', events);
        const finev = events.map((ev) => {
            // ev.args = {...ev.args};
            // return ev;
            return JSON.parse(JSON.stringify(ev));
        })
        // console.log('----finev', finev);
        return finev;
    }
    if (callback) {
        const listener = (event) => {
            // console.log('listner', eventName, event);
            // if (typeof event !== 'object') return;
            // callback(prepareEvents([event]));
        }
        instance.on(eventName, listener);
        unsubscribeAdd(() => {
            instance.removeAllListeners();
        });
        // if (!_eventSubs[contractName]) _eventSubs[contractName] = {};
        // _eventSubs[contractName][eventName] = async () => {
        //     const events = await ethContractEvents(contractName, eventName, filter);
        //     callback(events);
        // }
    }

    const prepevents = prepareEvents(events);
    // console.log('---events2', prepevents);
    return prepevents;
}
taylor.extendfn('eth-contract-events', ethContractEvents);

function getInfoFromEvent (ev) {
    // console.log('getInfoFromEvent', ev);
    let {contentHash, tag, originalContentHash, originalTag} = ev.args;
    if (!contentHash) {
        [contentHash, tag] = ev.args;
    }
    if (!contentHash || !tag) return;
    if (typeof contentHash === 'object') {
        contentHash = contentHash._hex;
    }
    if (typeof tag === 'object') {
        tag = tag._hex;
    }
    if (originalContentHash && typeof originalContentHash === 'object') {
        originalContentHash = originalContentHash._hex;
    }
    if (originalTag && typeof originalTag === 'object') {
        originalTag = originalTag._hex;
    }
    contentHash = ethers.BigNumber.from(contentHash);
    tag = ethers.BigNumber.from(tag);
    const _tag = tag.toHexString().slice(2);
    const _contentHash = contentHash.toHexString().slice(2);
    const _jurisdiction = _tag.slice(0, 2);
    const _data = _tag.slice(2, 18);
    const _tags = _tag.slice(18, 22);
    const _lat = _tag.slice(22, 38);
    const _long = _tag.slice(38, 54);
    const prefix = _tag.slice(54, 58);
    const jurisdiction = hexToInt(_jurisdiction, 1);
    const datevalue = hexToInt(_data, 8);
    const tags = hexToInt(_tags, 2);
    const lat = hexToInt(_lat, 8) / Math.pow(10, 15);
    const long = hexToInt(_long, 8) / Math.pow(10, 15);
    const ipfsHash = prefix + _contentHash;

    return {
        jurisdiction,
        datevalue,
        tags: [tags],
        coordinates: [lat, long].join(','),
        ipfs: ipfsHash,
        dataTag: tag.toHexString(),
        contentHash: contentHash.toHexString(),
        originalContentHash,
        originalTag,
    }
}

taylor.extendfn('whistle-from-events', (events = []) => {
    const coords = [];
    // console.log('whistle-from-events', events);
    for (let ev of events) {
        try {
            const coord = getInfoFromEvent(ev);
            if (coord && coord.coordinates && (coord.coordinates[0] || coord.coordinates[0] === 0) && (coord.coordinates[1] || coord.coordinates[1] === 0)) {
                coords.push(coord);
            }
        } catch (e) {console.debug(e)}
    }
    // console.log('------whistle-from-events', coords);
    return coords;
});
function linkFromEventData (d) {
    // console.log('linkFromEventData', d.coordinates, d.datevalue);
    const params = locationParams();
    const _ipfsHash = params ? params.ipfs : null;

    const getLink = () => {
        const bin = hextou8(d.ipfs);
        let url = '/?';
        if (_ipfsHash) url += `ipfs=${_ipfsHash}&`;
        url += `input=`;
        let ipfsHash;
        try {
          const cid = parseCID(bin);
          ipfsHash = cid.toString();
        } catch (e) {
          console.debug(e);
        }
        console.log('ipfsHash', ipfsHash);
        const input = encodeURIComponent(JSON.stringify({submissionIpfsHash: ipfsHash}));
        url += input;
        url += '#new'; // go to submission directly
        console.log('url', url);
        return url;
    }
    const date = new Date(parseInt(d.datevalue)).toUTCString();
    return {
        coordinates: d.coordinates,
        label: date,
        info: Object.entries(d || {}).map(v => v.join(': ')).join('; '),
        link: getLink,
    }
}
taylor.extendfn('whistle-from-event', getInfoFromEvent);
taylor.extendfn('whistle-link-from-data', linkFromEventData);
taylor.extendfn('whistle-map-data', (eventsData = []) => {
    // console.log('------whistle-map-data', eventsData);
    const data = eventsData.map((d) => {
        return linkFromEventData(d);
    });
    // console.log('-----whistle-map-data', data);
    return data;
});
function hextou8 (value) {
    let arr = [];
    let _value = value.slice(0, 2) === '0x' ? value.slice(2) : value;
    _value = _value.length % 2 === 0 ? _value : ('0' + _value);
    const nibbles = _value.split('');
    nibbles.forEach((a, i) => {
        if (i % 2 === 0) arr.push(parseInt(nibbles[i] + nibbles[i + 1], 16));
    });
    return new Uint8Array(arr);
}
function u8tohex (val) {
    return [...val].map(v => {
        return v.toString(16).padStart(2, '0')
    }).join('');
}
function intToHexPad (v, size) {
    const _v = new BN(v.toString(), 10)
    return _v.toTwos(size * 8).toString(16, size * 2);
}
function hexToInt (v, size) {
    const _v = v.slice(0, 2) === '0x' ? v.slice(2) : v;
    return new BN(_v, 16).fromTwos(8 * size).toString(10);
}
function removeNonProps (props) {
    delete props.width;
    delete props.minWidth;
    delete props.maxWidth;
    delete props.size;
    delete props.pad;

    if (props.hidden) props.hidden = true;
    return props;
}

const globalState = {};
globalState[DEFAULT_ABIS[0].name] = DEFAULT_ABIS[0];
globalState[DEFAULT_ABIS[1].name] = DEFAULT_ABIS[1];
window.globalState = globalState;
const usedContracts = {};
let usedInput = {};

const labelsFromStr = (args = '') => args.split(',').filter((v) => v).map(v => v.trim());
const allLabelsFromStr = (args = '') => args.split(',').map(v => v.trim());

function getInWorkContracts () {
    return Object.values(usedContracts);
}

function getInWorkInput () {
    delete usedInput.somethingnotexisting;
    return usedInput;
}

async function getUrl () {
    const ipfsHash = await exportMark({});
    return globalCommands['marks-url-ipfs'](ipfsHash.path, getInWorkInput());
}

async function exportContract (compiled) {
    return ipfsSet(compiled);
}

async function exportMark ({chain, contracts, content, styles, manual} = {}) {
    chain = chain || (await globalCommands['eth-provider-getNetwork']()).chainId;
    contracts = contracts || getInWorkContracts();
    content = content || storage.getItem(STORAGE_CODE);
    manual = manual || storage.getItem(STORAGE_MANUAL);
    return ipfsSet({
        chain,
        contracts,
        content,
        styles,
        manual,
    });
}

// if the contract comes from url, it may rewrite any existing contract
async function setUsedContract (name, address, ipfs, abi) {
    usedContracts[name] = {name, address, ipfs};

    if (!ipfs && abi) {
        const hash = await ipfsSet({
            output: {
                abi,
            }
        });
        if (!hash) {
            console.debug('No hash');
            return;
        }
        usedContracts[name].ipfs = hash.path;
        globalState[name] = usedContracts[name];
        try {
            let contracts = JSON.parse(storage.getItem(STORAGE_MENU));
            contracts = contracts.map((contract) => {
                if (contract.name === name) return {...contract, ...usedContracts[name]};
                return contract;
            });
            storage.setItem(STORAGE_MENU, JSON.stringify(contracts));
        } catch (e) {
            console.debug('Could not update ipfs addresses.');
        }
    }
}

function commandFailure(msg, command = '') {
    console.debug('executeCommand', msg, command);
    return msg;
}

async function executeCommand (command, inputLabels = []) {
    if (!command) return commandFailure('No command given');

    const _args = [];
    const _argsobj = [];
    for (let v of inputLabels) {
        const arg = `(get-state "${v}")`;
        _args.push(arg);
        _argsobj.push(`"${v}" ${arg}`)
    }

    const expression = `(${command} ${_args.join(' ')} {${_argsobj.join(' ')}} )`;

    let result;
    try {
        result = await taylor.eval(expression);
        result = taylor.interop.tay2js(result);
    } catch (e) {
        return commandFailure(e.message)
    }
    return result;
}

function useListener(props) {
    const {label, listen, initvalue} = props;
    const [value, setValue] = React.useState(safeChoice(initvalue, getState(label)));

    useEffect(() => {
        const listener = (e) => {
            if (listen && e.detail !== listen) return;
            setValue(getState(label));
        }
        registry.addEventListener(label, listener);

        return function cleanup () {
            registry.removeEventListener(label, listener);
        }
    }, [label]);
    return value;
}

function useListenerWithOnChange(props) {
    let {label, listen, initvalue} = props;
    // initvalue comes from the default value set in the mark component
    // but there might be values coming from preset input (url)
    initvalue = safeChoice(getState(label), initvalue);
    label = label || 'somethingnotexisting';
    const [value, setValue] = React.useState(initvalue);
    const onChange = (v) => {
        setValue(v);
        usedInput[label] = v;
    }

    React.useEffect(() => {
        setStateNoEvent(label, initvalue);
    }, [])

    useEffect(() => {
        const listener = (e) => {
            if (listen && e.detail !== listen) return;
            setValue(getState(label));
        }
        registry.addEventListener(label, listener);

        return function cleanup () {
            registry.removeEventListener(label, listener);
        }
    }, [label]);
    return [value, onChange];
}

function useListeners(props) {
    let {labels=[], listen} = props;
    const initv = {};
    labels = labels || [];
    if (typeof labels === 'string') labels = [labels];
    if (!(labels instanceof Array)) labels = [];
    labels.forEach((label) => {initv[label] = getState(label)});
    const [changed, setChanged] = React.useState(0);
    const [values, setValues] = React.useState(initv);

    useEffect(() => {
        const listeners = {};
        labels.forEach(label => {
            const listener = (e) => {
                if (listen && e.detail !== listen) return;
                const newvalues = {...values};
                newvalues[label] = getState(label);
                setValues(newvalues);
                setChanged(changed + 1);
            }
            registry.addEventListener(label, listener);
            listeners[label] = listener;
        });

        return function cleanup () {
            for (let label of labels) {
                registry.removeEventListener(label, listeners[label]);
            }
        }
    });
    return values;
}

function stringify (v) {
    return (v
        ? (typeof v === 'object'
            ? (v._isBigNumber
                ? ethers.BigNumber.from(v._hex).toString()
                : JSON.stringify(v)
            ) : v.toString()
        ) : v
    );
}

// chainId: @chainId.a.b right @network lala @ala.bala
// another @chain[0].lala.bb
function parseTemplate (template = '', v = '', values = {}) {
    let parsed = template;
    const toreplace = template.match(/@(\w*\d*\.*\[*\]*)*/g);

    if (!toreplace) return template;
    for (let t of toreplace) {
        const tag = t.slice(1);
        let value;
        try {
            // eslint-disable-next-line
            value = (function () {const res = v; return eval(`res.${tag}`)})();
        } catch(e) {
            // console.debug(e);
            try {
                // eslint-disable-next-line
                value = (function () {const res = values; return eval(`res.${tag}`)})();
            } catch(e) {
                // console.debug(e);
                try {
                    // eslint-disable-next-line
                    value = (function () {const res = v; return eval(`res${tag}`)})();
                } catch(e) {};
            }
        };
        if (typeof value === 'undefined') value = v;
        value = safestringify(value);
        parsed = parsed.replace(t, value);
    }
    return parsed;
}

function AbiFormFunctionWrap(props) {
    const {labels={}} = props;
    const values = useListeners({labels: Object.values(labels)});

    const v = {};
    Object.keys(labels).forEach((l) => {
        v[l] = values[labels[l]];
    });

    const onChange = (formData) => {
        Object.keys(formData).forEach((label) => {
            const stateLabel = labels[label];
            setState(stateLabel, formData[label]);
        });
    }

    return <AbiFormFunction {...props} values={v} onChange={onChange}/>
}

const TemplateDisplay = (comp) => (props) => {
    const {value, values, children} = props;
    const displayValue = handleTemplate(value);
    function handleTemplate (v) {
        if (!children) {
            return stringify(v);
        }
        return parseTemplate(children[0], v, values);
    }
    const _props = {...props};
    if (_props.hidden) _props.hidden = true;
    return comp(_props, displayValue);
}

const TextDisplay = (props) => {
    const comp = (_props, children) => (<p {..._props}>{children}</p>);
    return TemplateDisplay(comp)(props);
}

const SpanDisplay = (props) => {
    const comp = (_props, children) => (<span {..._props}>{children}</span>);
    return TemplateDisplay(comp)(props);
}

const TemplateComponent = (comp) => (props) => {
    const {label, input, command, listen} = props;
    const initvalue = props.value;
    const inputLabels = labelsFromStr(input);
    const values = useListeners({labels: inputLabels, listen});
    const [value, setValue] = useListenerWithOnChange({label, initvalue});

    const _values = JSON.stringify(values);

    if (label) setStateNoEvent(label, value);

    React.useEffect(() => {
        const runCommand = async () => {
            if (command) {
                let res = await executeCommand(command, inputLabels);
                if (label) setState(label, res);
                setValue(res);
            }
            else if(input) {
                setValue(values);
            }
        }
        runCommand();
    }, [command, values, _values]);
    return comp(props, value, values);
}

const Text = (props) => {
    const comp = (_props, value, values) => (<TextDisplay {..._props} value={value} values={values}/>);
    return TemplateComponent(comp)(props);
}

const SpanWrap = (props) => {
    const comp = (_props, value, values) => (<SpanDisplay {..._props} value={value} values={values}/>);
    return TemplateComponent(comp)(props);
}

const KatekDisplay = (props) => {
    const comp = (_props, children) => {
        return (<TeX math={children} />);
    }
    return TemplateDisplay(comp)(props);
}

const Katek = (props) => {
    const comp = (_props, value) => (<KatekDisplay {..._props} value={value}/>)
    return TemplateComponent(comp)(props);
}

const Button = (props) => {
    const {command, input, output, icon} = props;
    const inputLabels = labelsFromStr(input);
    const outputLabels = labelsFromStr(output);

    const onClick = async () => {
        if (!command) return;
        let res = await executeCommand(command, inputLabels);
        if (typeof res === 'undefined') return;
        res = [res];
        // if (!(res instanceof Array)) res = [res];
        outputLabels.forEach((label, i) => {
            if (res[i]) {
                setState(label, res[i]);
            }
        });
    }
    let btn;
    if (icon) {
        const title = props.title || props.children;
        const _props = {size: props.size, width: props.width, height: props.height}
        const _propsbtn = removeNonProps({...props});
        delete _propsbtn.icon;
        btn = (
            <button {..._propsbtn} onClick={onClick}>
                <Icon {..._props} title={title} />
            </button>
        )
    }
    else {
        btn = (<button {...props} onClick={onClick}>{props.children}</button>)
    }
    return btn;
}

function Icon (props = {}) {
    const src = props.src || ("./menu/" + props.title + '.svg')
    const width = (props.size || props.width || 20) + 'px';
    const height = (props.size || props.height || 20) + 'px';
    const _onClick = () => props.onClick ? props.onClick() : null;
    return <img onClick={_onClick} style={{maxWidth: '100%', width, height}} src={src}></img>
}

const LinkDisplay = (props) => {
    const _children = [props.src];
    const src = TemplateDisplay((_props, displayValue) => {
        return displayValue;
    })({...props, children: _children});

    const comp = (_props, children) => {
        return (<a href={src || _props.src} target="_blank">{children}</a>);
    }
    return TemplateDisplay(comp)(props);
}

const Link = (props) => {
    const comp = (_props, value, values) => (<LinkDisplay {..._props} value={value} values={values}/>);
    return TemplateComponent(comp)(props);
}

// <input type="file" accept="image/*" capture="camera">
// <input type="file" accept="video/*" capture="camera">
const Input = (props) => {
    const {label, pad = true} = props;
    const initvalue = props.value || props.children
    const [value, setValue] = useListenerWithOnChange({label, initvalue});

    const setGlobal = (v, eventName) => {
        setState(props.label, v, eventName);
    }

    const onChange = (e) => {
        let v = e.target.value;
        if (props.type === 'file') {
            setState(props.label + '_files', e.target.files);
        }
        setGlobal(v, 'onChange');
        setValue(v);
    }
    const onSubmit = (e) => {
        setGlobal(value, 'onSubmit');
    }
    const onKeyDown = (e) => {
        if (e.code === 'Enter') setGlobal(value, 'onSubmit');
    }
    const _blank = pad ? <Icon title="blank"/> : <span></span>;
    const _props = removeNonProps({...props});
    delete _props.children;
    _props.size = props.size;
    const _styles = {
        ..._props.styles || {},
        marginRight: 5,
        width: props.width,
    };
    if (props.type !== 'file') _props.value = value;

    return (
        <>
        {_blank}
        <input {..._props} style={_styles} onChange={onChange} onBlur={onSubmit} onKeyDown={onKeyDown}></input>
        </>
    )
}

const Textarea = (props) => {
    const {label} = props;
    const initvalue = props.value || props.children
    const [value, setValue] = useListenerWithOnChange({label, initvalue});

    const setGlobal = (v, eventName) => {
        setState(props.label, v, eventName);
    }

    const onChange = (e) => {
        setGlobal(e.target.value, 'onChange');
        setValue(e.target.value);
    }
    const onSubmit = (e) => {
        setGlobal(value, 'onSubmit');
    }
    const _props = {...props};
    if (_props.hidden) _props.hidden = true;
    delete _props.children;
    const _styles = {
        ..._props.styles || {},
        marginRight: 5,
    };

    return (
        <AntdTextArea {..._props} style={_styles} value={value} onChange={onChange} onPressEnter={onSubmit}></AntdTextArea>
    )
}

const Image = (props) => {
    return (<img alt="image" {...props}></img>)
}

const Video = (props) => {
    const _props = {
        width: "560",
        height: "315",
        title: 'video player',
        ...props
    };

    return(
        <iframe frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen style={{padding: 10}} {..._props}></iframe>
    );
}

const _Select = (props) => {
    let {options, label} = props;
    if (typeof options === 'string') options = getState(options);

    const onSelect = (v) => {
        setState(label, v);
    }

    const _options = options.map((opt, i) => {
        if (typeof opt === 'string') return <option value={opt}>{opt}</option>
        return <Option key={i} value={opt.value}>{opt.label}</Option>
    })

    return (<Select
        showSearch
        style={{ width: 200 }}
        placeholder="Search to Select"
        optionFilterProp="label"
        filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }
        onChange={onSelect}
    >{_options}</Select>);
}

function useContract (props) {
    let {name, abi: _abi, address: _address} = props;

    let [abi, setAbi] = React.useState(_abi);
    const [address1, setAddress] = React.useState(_address);
    const address2 = useListener({label: props.address});

    React.useEffect(() => {
        if (name) {
            let contract = globalState[name];
            if (contract) {
                _address = _address || contract.address;
                _abi = _abi || contract.abi;
                setUsedContract(name, _address, contract.ipfs, _abi);
            }
            setAbi(_abi);
            setAddress(_address);
        }
    }, [name]);

    const address = address2 || address1;
    if (!abi) abi = DEFAULT_ABIS[0].abi;
    if (address && address.slice(0, 2) !== '0x') address = null;
    if (!(abi instanceof Array)) abi = [abi];
    return {address, abi};
}

function parseAbiArgs (args, fnabi) {
    return args.map((v, i) => {
        if (i >= fnabi.inputs.length) return v;
        if (typeof v === 'string') {
            const inputabi = fnabi.inputs[i];
            if (inputabi.type === 'tuple' || inputabi.type.includes('[]')) {
                try {
                    return JSON.parse(v);
                } catch (e) {
                    console.log('Could not parse as JSON: ', v);
                    return v;
                }
            }
        }
        return v;
    });
}

taylor.extendfn('react-Contract-call', (name, fname, ...args) => {
    let contract;
    if (typeof name === 'string') contract = globalState[name];
    else if (typeof name === 'object') contract = name;

    if (!contract) return 'No contract found';

    const {address, abi} = contract;

    if (!address) return 'Missing address';
    if (!abi) return 'Missing abi';
    let instance;
    try {
        instance = globalCommands['eth-Contract'](address, abi);
    } catch (e) {
        const msg = 'Contract instance: ' + e.message;
        return <Text children={[msg]}/>
    }
    if (!instance[fname]) return 'No function found';
    return instance[fname](...args).catch(e => e.message);
});

const Contract = (props) => {
    const {function: fn, children} = props;
    let {name, abi: _abi, address: _address, input: argdeps = '', output: outputLabel, hidden, showbtn} = props;
    let fname = fn;
    const argLabels = allLabelsFromStr(argdeps);
    showbtn = showbtn === 'true' ? true : (showbtn === 'false' ? false : showbtn)

    // TODO label -> output!

    let [abi, setAbi] = React.useState(_abi);
    const [address1, setAddress] = React.useState(_address);
    const [expanded, setExpanded] = React.useState(false);

    const address2 = useListener({label: props.address});

    React.useEffect(() => {
        if (name) {
            let contract = globalState[name];
            if (contract) {
                _address = _address || contract.address;
                _abi = _abi || contract.abi;
                setUsedContract(name, _address, contract.ipfs, _abi);
            }
            setAbi(_abi);
            setAddress(_address);
        }
    }, [name]);

    const address = address2 || address1;

    if (!abi) abi = DEFAULT_ABIS[0].abi;

    if (!abi) return <Text children={["No abi found"]}/>
    if (!address) return <Text children={["No address found"]}/>
    if (address.slice(0, 2) !== '0x') return <Text children={["No address found"]}/>
    if (!(abi instanceof Array)) abi = [abi];

    let instance;
    try {
        instance = globalCommands['eth-Contract'](address, abi);
    } catch (e) {
        const msg = 'Contract instance: ' + e.message;
        console.debug(msg);
        return <Text children={[msg]}/>
    }

    let fnabi;
    if (fname) fnabi = abi.filter((v => v.name === fname));
    else {
        fnabi = abi;
        fname = abi[0].name;
    }

    if (!fnabi || fnabi.length === 0) {
        return <Text children={["No abi found"]}/>;
    }
    fnabi = fnabi[0];
    const payable = fnabi.payable || fnabi.stateMutability === 'payable';
    const isTx = !fnabi.constant && fnabi.stateMutability === 'nonpayable';
    const command = async (..._args) => {
        let args = _args.slice(0, -1);
        args = parseAbiArgs(args, fnabi);
        let tx = {};
        if (payable) {
            if (args.length < 1) return 'Not enough inputs';
            tx.value = valueToHex(args.pop());
        }
        if (payable || isTx) {
            let estimatedGas = 1000000;
            try {
                estimatedGas = await instance.estimateGas[fname](...args, tx);
                estimatedGas.add(ethers.BigNumber.from(50000));
            }
            catch (e) {console.debug(e);}

            tx.gasLimit = estimatedGas;
            tx.gasPrice = 21000000000; // 21 gwei
        }
        let result;
        if (isTx || payable) {
            console.debug('tx', fname, ...args, tx);
            try {result = await instance[fname](...args, tx);}
            catch (e) {result = e.message;}
        }
        else {
            try {result = await instance[fname](...args);}
            catch (e) {result = e.message;}
        }
        if (result && typeof result === 'object' && result.wait) result = result.wait();
        return result;
    }
    const label = ('eth-contract-' + address + '-' + fname).replace(' ', '');
    taylor.extendfn(label, command);

    const args = [];
    const _labels = {};
    let inputs;
    let abiInputs = fnabi.inputs.concat(payable ? [{name: 'value (WEI)', type: 'uint256'}] : []);

    inputs = abiInputs.map((inp, i) => {
        let label;
        if (argLabels[i]) label = argLabels[i];
        else {
            label = (name + '-' + fname + '-' + inp.name).replace(' ', '');
        }
        args.push(label);
        _labels[inp.name] = label;
        const _props = {label, pad: false};
        if (hidden && hidden.includes('input')) _props.hidden = true;
        return <Input key={'input' + i} placeholder={`${inp.name + ': ' + inp.type}`} {..._props}/>
    });
    const _args = args.join(',');
    const _output = outputLabel || label + '_output';

    const btnprops = {children: fname, input: _args, command: label, output: _output};
    if (payable) {
        btnprops.icon = true;
        btnprops.title = 'fingerprint';
    }

    let showButton = inputs.length > 0;
    if (typeof showbtn !== 'undefined') showButton = showbtn;

    const _btn = showButton ? <Button key={1} {...btnprops} /> : <></>;

    const outputProps = {children};
    if (hidden && hidden.includes('output')) outputProps.hidden = true;

    let outputTextDisplay;
    if (isTx || payable) outputTextDisplay = <JsonWrap {...outputProps} label={_output}/>;
    else outputTextDisplay = <Text {...outputProps} label={_output}/>;

    const outputText = showButton
        ? outputTextDisplay
        : (<>
            {outputTextDisplay}
            <Text {...outputProps} command={label} input={_args} label={_output} hidden={true}/>
        </>);

    let _form;
    if (!expanded) {
        _form = (<>{inputs}{_btn}</>);
    } else {
        const onError = (v) => console.error(v);
        _form = (<>
            {inputs}{_btn}
            <AbiFormFunctionWrap key={2} labels={_labels} abi={{...fnabi, inputs: abiInputs}} contractInstance={instance} onError={onError} />
        </>);
    }

    const hiddenInput = hidden && hidden.includes('input');
    const more = (inputs.length > 0 && !hiddenInput) ? <Icon className="btn-icon" title={"more"} onClick={() => setExpanded(!expanded)}/> : <></>;

    return (
        <div>
            {more}
            {_form}
            {outputText}
        </div>
    )
}

const ContractWatch = (props) => {
    const {event, command, output} = props;
    const {address, abi} = useContract(props);
    const [instance, setInstance] = React.useState();
    const [error, setError] = React.useState();
    const [events, setEvents] = React.useState([]);

    let evabi = (abi || []).filter((v => v.name === event))[0];

    React.useEffect(() => {
        if (!address || !abi) return;
        let instance;
        try {
            instance = globalCommands['eth-Contract'](address, abi);
            setInstance(instance);
        } catch (e) {
            setError(e);
        }
    }, [address]);

    React.useEffect(() => {
        if (!instance) return;
        const getEvents = async () => {
            const events = (await instance.queryFilter(event)).map((e, i) => {
                e.index = i;
                return e;
            });
            // console.log('events', events);
            setEvents(events);
        }
        getEvents();
    }, [instance]);

    if (!abi) return <Text children={["No abi found"]}/>
    if (!address) return <Text children={["No address found"]}/>
    if (error) return <Text children={[error.message]}/>

    // console.log('abi', abi, evabi);
    const ItemArgs = ({args, index}) => {
        const keys = evabi.inputs.map(v => v.name);
        const base = address + '_' + evabi.name + '_' + index + '_';
        const argNames = [];
        const argComponents = keys.map((key, i) => {
            const argname = base + key;
            setStateNoEvent(argname, args[key]);
            argNames.push(argname);
            return (
                <div key={i} style={{display: 'flex'}}>
                    <span>{key}: </span>
                    <Text value={args[key]} />
                </div>
            );

            // return <span key={i}>{key}: </span>
        });
        return (
            <div>{argComponents}<Button command={command} input={argNames.join(',')} output={output} children={['go']}/></div>
        )
    }

    return (
        <List
        bordered
        dataSource={events}
        renderItem={item => (
            <List.Item>
                <ItemArgs {...item} />
            </List.Item>
        )}
        />
    )

}

function MapViewWrap (props) {
    const comp = (_props, value, values) => {
        let _values;
        if (typeof values !== 'object')  _values = [];
        else _values = Object.values(values)[0];
        if (_values && _values instanceof Array) {
            _values = _values.filter(v => typeof v === 'object').map(v => {
                v.coordinates = parseCoords(v.coordinates);
                return v;
            });
        }
        return (<MapView {..._props} value={value} values={_values}/>);
    }
    return TemplateComponent(comp)(props);
}

function MapWrap (props) {
    const {label, listen} = props;
    const _initvalue = props.value;
    const initvalue = _initvalue ? parseCoords(_initvalue) : _initvalue;
    const [_value, setValue] = useListenerWithOnChange({label, listen, initvalue});

    if (label) setStateNoEvent(label, value);

    const value = parseCoords(_value);

    const _onChange = (center) => {
        const v = [center.lat, center.lng].join(',');
        if (props.label) setState(props.label, v);
        setValue(v);
    }

    const _props = {...props, style:{}}
    _props.style.width = props.width;
    _props.style.height = props.height;
    _props.onChange = _onChange;
    _props.center = value;
    return <Map {..._props}/>;
}

const InputComponent = (Comp, formatToCore, formatToComponent) => (props) => {
    const {label} = props;
    const initvalue = props.value || props.children
    const [value, setValue] = useListenerWithOnChange({label, initvalue});

    if (label) setStateNoEvent(label, value);

    React.useEffect(() => {
        const event = new CustomEvent(props.label, {detail: 'onChange'});
        registry.dispatchEvent(event);
    }, []);

    const onChange = (v) => {
        const val = formatToCore ? formatToCore(v) : v;
        if (label) setState(props.label, val);
        setValue(val);
        if (props.onChange) props.onChange(val);
    }

    const _props = {...props}
    _props.onChange = onChange;
    _props.value = formatToComponent ? formatToComponent(value) : value;
    return <Comp {..._props}/>;
}

const DatePickerWrap = (props) => {
    const formatToCore = (v) => {
        if (!v) return v;
        const val = v.valueOf();
        return val;
    }
    const formatToComponent = (v) => {
        const val = parseInt(v ? v : new Date().getTime());
        return moment(val);
    }
    const _props = {
        ...props,
        showTime: props.time ? true : false,
    }
    delete _props.time;
    return InputComponent(DatePicker, formatToCore, formatToComponent)(_props);
}

const DateRangeWrap = (props) => {
    const parse = (val) =>  val.split(',').map(v => parseInt(v.trim()));
    const formatToCore = (v) => {
        if (!v) return v;
        const val = [v[0].valueOf(), v[1].valueOf()];
        return val;
    }
    const formatToComponent = (v) => {
        const vals = typeof v === 'string' ? parse(v) : v;
        const v1 = parseInt(vals && vals[0] ? vals[0] : new Date().getTime());
        const v2 = parseInt(vals && vals[1] ? vals[1] : new Date().getTime());
        return [moment(v1), moment(v2)];
    }
    const _props = {
        ...props,
        showTime: props.time ? true : false,
    }
    delete _props.time;
    return InputComponent(RangePicker, formatToCore, formatToComponent)(_props);
}

const TreeSelectWrap = (props) => {
    const data = useListener({label: props.data}) || [];
    const formatToCore = (v) => {
        return v;
    }
    const formatToComponent = (v) => {
        return v;
    }

    const tProps = {
      treeCheckable: props.multiple ? true : false,
      showCheckedStrategy: SHOW_PARENT,
      placeholder: 'Please select',
      style: {
        width: props.width || '100%',
        minWidth: props.minWidth || '50px',
      },
    };
    const _props = removeNonProps({
        ...tProps,
        ...props,
        treeData: data,
        value: props.value,
    });
    return InputComponent(TreeSelect, formatToCore, formatToComponent)(_props);
}

const EditorWrap = (props) => {
    const formatToCore = (v) => v;
    const formatToComponent = (v) => v;
    const _props = {
        ...removeNonProps({...props}),
        value: props.value,
        width: props.width || undefined,
        height: props.height || undefined,
    };
    return InputComponent(Editor, formatToCore, formatToComponent)(_props);
}

const SelectWrap = (props) => {
    const formatToCore = (v) => v;
    const formatToComponent = (v) => v;
    const _props = {
        ...removeNonProps({...props}),
        value: props.value,
    };

    return InputComponent(SelectInternal, formatToCore, formatToComponent)(_props);
}

const SelectInternal = (props) => {
    let {options, optionLabel, optionId, optionValue} = props;
    if (typeof options === 'string') options = getState(options);

    optionId = optionId || optionValue;

    const _options = options || [];
    if (!(_options instanceof Array)) return <p>Options needs to be an array: {_options}</p>;

    const isString = typeof _options[0] === 'string';
    const __options = _options.map((option) => {
        const obj = typeof option === 'string' ? {value: option, label: option} : option;
        if (optionLabel) obj.label = obj[optionLabel];
        return obj;
    });

    const optionComponents = __options.map((option, i) => {
        return <Option key={i} value={i}>{option.label}</Option>;
    });

    const onChange = (v) => {
        const indx = parseInt(v);
        if (!indx && indx !== 0) return;
        if (!__options[indx]) return;
        let value = __options[indx];
        if (isString) value = value.value;
        else if (optionValue) value = value[optionValue];
        console.log('onChange', v, value);
        if (props.onChange) props.onChange(value);
    }

    let value = props.value;
    if (!isString) {
        let compareValue = value;
        let label = 'value';
        if (optionId) {
            compareValue = optionValue ? value : value[optionId];
            label = optionId;
        }
        value = __options.findIndex(v => v[label] === compareValue);
        if (value === -1) value = 0;
    }
    const _props = {
        ...removeNonProps({...props}),
        value,
        style: props.style || {},
    };
    delete _props.options;
    delete _props.optionId;
    delete _props.optionLabel;
    delete _props.optionValue;

    return (<Select
        optionFilterProp="label"
        {..._props}

        filterOption={(input, option) => {
            console.log('filterOption', input, option);
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }}
        filterSort={(optionA, optionB) => {
            console.log('filterSort', optionA, optionB);
            return optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        }}
        onChange={onChange}
    >{optionComponents}</Select>);
}

const MarkdownDisplay = (props) => {
    const comp = (_props, children) => {
        return (
            <ReactMarkdown {..._props} remarkPlugins={[
                gfm,
                breaks,
                math,
                katex,
                rslug,
            ]} children={children}/>
        )
    };
    return TemplateDisplay(comp)(props);
}

function MarkdownWrap (props = {}, children = '') {
    const comp = (_props, value, values) => {
        return (<MarkdownDisplay {..._props} value={value} values={values}/>)
    };
    return TemplateComponent(comp)(props);
}

const JsonRaw = (props, children = {}) => {
    let value = props.value || children;
    const {style = {}, collapsed = true} = props;
    if (typeof value === 'string') {
        try {
            value = JSON.parse(children);
        } catch (e) {}
    }
    if (typeof value !== 'object') value = {value};
    try {
        value = JSON.parse(stringify(value));
    } catch (e) {
        value = `${e.message} - ${value}`;
    }
    const _style = Object.assign({ fontSize: 16}, style);
    return (
        <ReactJson
            src={ value }
            name={null}
            theme={'twilight'}
            style={_style}
            collapsed={collapsed}
        />
    )
};

const JsonDisplay = (props) => {
    return TemplateDisplay(JsonRaw)(props);
}

function JsonWrap (props) {
    const comp = (_props, value, values) => (<JsonDisplay {..._props} value={value} values={values}/>);
    return TemplateComponent(comp)(props);
}

taylor.extendfn('react-Text', (props) => {
    class Component extends React.Component {
        render () {
            return <Text {...props} />;
        }
    }
    return <Component />
});
taylor.extendfn('react-Button', (props) => {
    class Component extends React.Component {
        render () {
            return <Button {...props} />;
        }
    }
    return <Component />
});
taylor.extendfn('react-Select', (props) => {
    class Component extends React.Component {
        render () {
            return <SelectWrap {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-Contract', (props) => {
    class Component extends React.Component {
        render () {
            return <Contract {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-Link', (props) => {
    class Component extends React.Component {
        render () {
            return <Link {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-Json', (props) => {
    class Component extends React.Component {
        render () {
            return <JsonWrap {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-Input', (props) => {
    class Component extends React.Component {
        render () {
            return <Input {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-video', (props) => {
    class Component extends React.Component {
        render () {
            return <Video {...props} />;
        }
    }
    return <Component />
});

taylor.extendfn('react-editor', (props, children) => {
    return <Editor {...props}>{children}</Editor>
});

taylor.extendfn('react-json', (props, children) => {
    let value = props.value || children;
    if (typeof value !== 'object') value = {data: value};
    return <JsonRaw {...props}>{children}</JsonRaw>;
});

taylor.extendfn('react-tag', (props, children) => {
    children = children || props.children;
    return <Tag {...props}>{children}</Tag>
});

taylor.extendfn('react-tabs', (props, children) => {
    return <Tabs {...props}>{children}</Tabs>
});

taylor.extendfn('react-tabpane', (props, children) => {
    return <TabPane {...props} children={children}/>
});

taylor.extendfn('react-radio-group', (props, children) => {
    const onChange = props.onChange ? (e) => props.onChange(e.target.value) : undefined;
    return <Radio.Group {...props} onChange={onChange}>{children}</Radio.Group>
});

taylor.extendfn('react-radio-button', (props, children) => {
    return <Radio.Button {...props} children={children}/>
});

taylor.extendfn('react-table', (props) => {
    function ReactTable (props) {
        let rowClassName;
        let indexLabel = props.index;
        let data = props.data || [];
        if (props.data && props.data.value) data = props.data.value;

        useEffect(() => {
            const listener = () => {
                const value = getState(indexLabel);
                if (props.onChange) props.onChange(data[value]);
            }
            registry.addEventListener(indexLabel, listener);

            return function cleanup () {
                registry.removeEventListener(indexLabel, listener);
            }
        }, [indexLabel]);

        if (props.rowClassName) {
            rowClassName = (record, index) => {
                return record[props.rowClassName];
            }
        }

        const {columns} = props;

        return <Table {...props} columns={columns} dataSource={data} rowClassName={rowClassName}/>
    }
    return <ReactTable {...props}></ReactTable>;
})

taylor.extendfn('react-table-infinite', (props) => {
    function ReactTableInfinite (props) {
        let indexLabel = props.index;
        let data = props.data || [];
        if (props.data && props.data.value) data = props.data.value;

        useEffect(() => {
            const listener = () => {
                const value = getState(indexLabel);
                if (props.onChange) props.onChange(data[value]);
            }
            registry.addEventListener(indexLabel, listener);

            return function cleanup () {
                registry.removeEventListener(indexLabel, listener);
            }
        }, [indexLabel]);

        const {columns} = props;

        return <VirtualTable {...props} columns={columns} dataSource={data} />
    }
    return <ReactTableInfinite {...props}></ReactTableInfinite>;
});

taylor.extendfn('react-pagination', (props) => {
    return <Pagination {...props}/>
});

taylor.extendfn('react-drawer', (props, children) => {
    return <Drawer {...props}>{children}</Drawer>
});

taylor.extendfn('react-form', (props, children) => {
    return <DynamicForm {...props} children={children}></DynamicForm>
});

taylor.extendfn('react-modal', (props, children) => {
    return <ModalComponent {...props} children={children}></ModalComponent>
});

taylor.extendfn('react-meta', (props, children=[]) => {
    return (<Meta {...props} />);
});

taylor.extendfn('react-card', (props, children=[]) => {
    return (<Card hover {...props} >{children}</Card>)
});

taylor.extendfn('react-col', (props, children=[]) => {
    return (<Col {...props}>{children}</Col>);
});

taylor.extendfn('react-row', (props, children=[]) => {
    return (<Row {...props}>{children}</Row>);
});

const injectedProvider = `
class InjectedProvider {
    constructor () {
        this.listen();
    }
    listen () {
        window.addEventListener('message', (ev) => {
            if (ev.origin == window.location.href) return;
            if (!ev.data || ev.data.type !== 'command') return;
            if (!window.marks) {
                console.error('window.marks missing');
            }
            try {
                window.marks.evalCommand(ev.data.data);
            } catch (e) {
                console.error(e);
            }
        })
    }
}
marksProvider = new InjectedProvider();
`

function getContentWindow (id) {
    if (!id) return;
    const elem = document.getElementById(id);
    if (!elem) return;
    return elem.contentWindow;
}

taylor.extendfn('react-iframe', (props, children) => {
    class Component extends React.Component {
        componentDidMount() {
            const id = props.id || props.name;
            if (!id) return;
            const elem = document.getElementById(id);
            if (!elem) return;
            const w = elem.contentWindow;
            if (!w) return;

            if (props.mark) registerMarkInstance({id}, w);

            setTimeout(() => {
                const cw = getContentWindow(props.id);
                if (!cw) return;
                if (props.injection && cw.eval) cw.eval(props.injection);
            }, 100);

            setTimeout(function () {
                if (props.mark) {
                    if (!w.document || !w.document.createElement) return;
                    const head = w.document.getElementsByTagName('head');
                    if (!head || head.length === 0) return;
                    const script = w.document.createElement('script');
                    script.id = 'markinjection';
                    script.innerHTML = injectedProvider;
                    head[0].appendChild(script);
                }
            }, 300);
        }

        render () {
            const value = props.value || children;
            const _props = {
                ...props,
                src: value,
                id: props.id || props.name,
                name: props.name || props.id,
            }
            delete _props.injection;
            delete _props.mark;

            return <iframe {..._props}>{children}</iframe>
        }
    }
    return <Component />;
});

const components = {
    Button,
    Input,
    Textarea,
    Link,
    Image,
    Video,
    Text,
    Span: SpanWrap,
    Select: _Select,
    TreeSelect: TreeSelectWrap,
    Contract,
    ContractWatch,
    TeX: Katek,
    Math: Katek,
    Map: MapWrap,
    MapView: MapViewWrap,
    Date: DatePickerWrap,
    DateRange: DateRangeWrap,
    Editor: EditorWrap,
    Markdown: MarkdownWrap,
    Json: JsonWrap,
}

globalState['addresses'] = DEFAULT_ADDRESSES;
globalState['DEFAULT_ADDRESSES'] = DEFAULT_ADDRESSES;
globalState['DEFAULT_TAGS'] = DEFAULT_TAGS;
globalState['DEFAULT_JURISDICTION'] = DEFAULT_JURISDICTION;
globalState['DEFAULT_CATEGORIES'] = DEFAULT_CATEGORIES;

function MarkdownViewer (props = {}, children = '') {
    children = children ? children : props.children;
    const {data = [], input = ''} = props;
    let _input = decodeURIComponent(input);
    if (_input && typeof _input === 'string') {
        try {
            _input = JSON.parse(_input);
            usedInput = {...usedInput, ..._input};
            for (let key of Object.keys(_input)) {
                setStateNoEvent(key, _input[key]);
            }
        } catch (e) {
            // console.debug('Decoding URL input', _input, e);
        }
    }

    data.forEach((d) => {
        globalState[d.name] = d;
    });

    return <MarkdownViewerComponent {...props} children={children}/>
}

const TayComponent = ({expression}) => () => {
    const ini = <span></span>;
    const [component, setComponent] = React.useState(ini);
    React.useEffect(() => {
        const setup = async () => {
            try {
                const comp = await taylor.eval(expression);
                if (isReact(comp)) setComponent(comp);
            } catch (e) {
                // console.debug('TayComponent', expression, e);
                setComponent(<p>{e.message}</p>);
            }
        }
        setup();
    }, []);
    return <ErrorBoundary>{component}</ErrorBoundary>;
}

function activateSlides (manualContainerClass, self) {
    const slide = document.getElementsByClassName('presentSlide');
    if (!slide || slide.length === 0) return;
    if (self.initSlide) return;

    slide[0].classList.add('presentSlide-active');

    const goto = (from, index) => {
        const elem = document.getElementsByClassName('presentSlide')[from];
        if (!elem) return;
        const elem2 = document.getElementsByClassName('presentSlide')[index]
        if (!elem2) return;

        elem.classList.remove('presentSlide-active')
        elem2.classList.add('presentSlide-active')

        self.activeSlide = index;
        // scaleSlide();
    }

    // scaleSlide();
    const btnleft = document.createElement('button');
    btnleft.classList.add('presBtnLeft');
    btnleft.addEventListener('click', () => {
        let index = self.activeSlide;
        goto(index, index - 1);
    });
    const iconleft = document.createElement('img');
    iconleft.src = './menu/arrow-w.svg';
    iconleft.style.width = '100%';
    iconleft.style.height = '100%';
    btnleft.appendChild(iconleft);

    const btnright = document.createElement('button');
    btnright.classList.add('presBtnRight');
    btnright.addEventListener('click', () => {
        let index = self.activeSlide;
        goto(index, index + 1);
    });
    const iconright = document.createElement('img');
    iconright.src = './menu/arrow-e.svg';
    iconright.style.width = '100%';
    iconright.style.height = '100%';
    btnright.appendChild(iconright);

    document.getElementsByClassName(manualContainerClass)[0].appendChild(btnleft)
    document.getElementsByClassName(manualContainerClass)[0].appendChild(btnright)
    self.initSlide = true;
}

class MarkdownViewerComponent extends React.Component {
    constructor (props) {
        super(props);
        this.activeSlide = 0;
        this.initSlide = false;
    }
    componentWillUnmount () {
        unsubscribeResolve();
    }

    componentDidUpdate () {
        if (!this.props.showManual || !this.props.manualClass) return;
        const manualContainerClass = this.props.manualClass;
        activateSlides(manualContainerClass, this);
    }

    componentDidMount () {
        if (!this.props.showManual || !this.props.manualClass) return;
        const manualContainerClass = this.props.manualClass;
        activateSlides(manualContainerClass, this);
    }

    render () {
        const {props} = this;

        return <_MarkdownViewer {...props} />;
    }
}

function _MarkdownViewer (props = {}) {
    const {children, showManual, onToc} = props;
    let [nodes, simplifiedText = ''] = extractNodes(children);

    if (showManual) {
        simplifiedText = splitPresentation(simplifiedText);
    }

    const rtoc = _rtoc((tocvalue) => {
        if (!onToc) return;

        const md = toMarkdown(tocvalue);
        if (md) onToc(md);
    });

    const allcomponents = {...components};

    const labels = Object.keys(nodes);
    for (let label of labels) {
        allcomponents[label] = TayComponent(nodes[label]);
    }

    return (
        <ReactMarkdown remarkPlugins={[
            gfm,
            breaks,
            math,
            katex,
            directive,
            reactMarkdownRemarkDirective,
            rtoc,
            rslug,
        ]} {...props} components={allcomponents} children={simplifiedText}/>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: error.message };
    }

    componentDidCatch(error, errorInfo) {
        // console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
        // You can render any custom fallback UI
        return <p>{this.state.hasError}</p>
        }
        return this.props.children;
    }
}

return {extensions};
}

export default init;
