import React from 'react';
import { Form, Button, Upload } from 'antd';
import FormBuilder from 'antd-form-builder';

FormBuilder.defineWidget('upload', Upload);

export default (props = {}) => {
  const [form] = Form.useForm();
  let {metas, children, footerProps, formProps, showSubmit, updatedValues, ...rest} = props;
  metas = metas || [{fields: []}]
  footerProps = footerProps || {};
  formProps = formProps || {};
  if (!children || !(children instanceof Array)) children = [];
  let btnTitle = 'Submit';
  if (typeof showSubmit === 'object') {
    btnTitle = showSubmit.title || btnTitle;
    showSubmit = true;
  }
  if (updatedValues) {
    form.setFieldsValue(updatedValues);
  }

  const subforms = metas.map((meta, i) => {
    return (<fieldset {...formProps}>
      {meta.legend
        ? <legend>{meta.legend}</legend>
        : <></>
      }
      <FormBuilder meta={meta} form={form} key={i} />
    </fieldset>)
  });

  return (
    <Form {...rest} form={form}>
      {subforms}
      <Form.Item wrapperCol={{ span: 16, offset: 8 }} {...footerProps}>
        {children}
        {showSubmit
            ? <Button htmlType="submit" type="primary">{btnTitle}</Button>
            : <></>
        }
      </Form.Item>
    </Form>
  )
}
