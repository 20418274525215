const addresses = [
    {label:'Wrapped Ether (WETH)', value:'0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2'},
    {label:'BNB (BNB)', value:'0xB8c77482e45F1F44dE1745F52C74426C631bDD52'},
{label:'Tether USD (USDT)', value:'0xdac17f958d2ee523a2206206994597c13d831ec7'},
{label:'Uniswap (UNI)', value:'0x1f9840a85d5af5bf1d1762f925bdaddc4201f984'},
{label:'USD Coin (USDC)', value:'0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'},
{label:'ChainLink Token (LINK)', value:'0x514910771af9ca656af840dff83e8264ecf986ca'},
{label:'VeChain (VEN)', value:'0xd850942ef8811f2a866692a623011bde52a462c1'},
{label:'TrueUSD (TUSD)', value:'0x0000000000085d4780B73119b644AE5ecd22b376'},
{label:'Huobi BTC (HBTC)', value:'0x0316EB71485b0Ab14103307bf65a021042c6d380'},
{label:'UMA Voting Token v1 (UMA)', value:'0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828'},
{label:'Zilliqa (ZIL)', value:'0x05f4a42e251f2d52b8ed15e9fedaacfcef1fad27'},
{label:'yearn.finance (YFI)', value:'0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'},
{label:'BAT (BAT)', value:'0x0d8775f648430679a709e98d2b0cb6250d2887ef'},
{label:'Pundi X Token (PUNDIX)', value:'0x0fd10b9899882a6f2fcb5c371e17e70fdee00c38'},
{label:'1INCH Token (1INCH)', value:'0x111111111117dc0aa78b770fa6a738034120c302'},
{label:'Numeraire (NMR)', value:'0x1776e1f26f98b1a5df9cd347953a26dd3cb46671'},
{label:'Energy Web Token Bridged (EWTB)', value:'0x178c820f862b14f316509ec36b13123da19a6054'},
{label:'Bancor (BNT)', value:'0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c'},
{label:'Wrapped BTC (WBTC)', value:'0x2260fac5e5542a773aa44fbcfedf7c193bc2c599'},
{label:'Bitfinex LEO Token (LEO)', value:'0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3'},
{label:'HEX (HEX)', value:'0x2b591e99afe9f32eaa6214f7b7629768c40eeb39'},
{label:'chiliZ (CHZ)', value:'0x3506424f91fd33084466f402d5d97f05f8e3b4af'},
{label:'DENT (DENT)', value:'0x3597bfd533a99c9aa083587b074434e61eb0a258'},
{label:'Compound Uniswap (cUNI)', value:'0x35A18000230DA775CAc24873d00Ff85BccdeD550'},
{label:'SAND (SAND)', value:'0x3845badAde8e6dFF049820680d1F14bD3903a5d0'},
{label:'Theta Token (THETA)', value:'0x3883f5e181fccaf8410fa61e12b59bad963fb645'},
{label:'FEGtoken (FEG)', value:'0x389999216860ab8e0175387a0c90e5c52522c945'},
{label:'Compound USD Coin (cUSDC)', value:'0x39aa39c021dfbae8fac545936693ac917d5e7563'},
{label:'WAX Token (WAX)', value:'0x39bb259f66e1c59d5abef88375979b4d20d98022'},
{label:'FunFair (FUN)', value:'0x419d0d8bdd9af5e606ae2232ed285aff190e711b'},
{label:'XinFin XDCE (XDCE)', value:'0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2'},
{label:'DODO bird (DODO)', value:'0x43Dfc4159D86F3A37A5A4B3D4580b888ad7d4DDd'},
{label:'Orchid (OXT)', value:'0x4575f41308EC1483f3d399aa9a2826d74Da13Deb'},
{label:'Telcoin (TEL)', value:'0x467Bccd9d29f223BcE8043b84E8C8B282827790F'},
{label:'Wootrade Network (WOO)', value:'0x4691937a7508860f876c9c0a2a617e7d9e945d4b'},
{label:'Serum (SRM)', value:'0x476c5E26a75bd202a9683ffD34359C0CC15be0fF'},
{label:'Quant (QNT)', value:'0x4a220e6096b25eadb88358cb44068a3248254675'},
{label:'Compound Ether (cETH)', value:'0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5'},
{label:'Fantom Token (FTM)', value:'0x4e15361fd6b4bb609fa63c81a2be19d873717870'},
{label:'CelerToken (CELR)', value:'0x4f9254c83eb525f9fcf346490bbb3ed28a81c667'},
{label:'Binance USD (BUSD)', value:'0x4fabb145d64652a948d72533023f6e7a623c7c53'},
{label:'Livepeer Token (LPT)', value:'0x58b6a8a3302369daec383334672404ee733ab239'},
{label:'Voyager (VGX)', value:'0x5af2be193a6abca9c8817001f45744777db30756'},
{label:'NKN (NKN)', value:'0x5cf04716ba20127f1e2297addcf4b5035000c9eb'},
{label:'Compound Dai (cDAI)', value:'0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643'},
{label:'RLC (RLC)', value:'0x607F4C5BB672230e8672085532f7e901544a7375'},
{label:'Gnosis (GNO)', value:'0x6810e776880c02933d47db1b9fc05908e5386b96'},
{label:'Dai Stablecoin (DAI)', value:'0x6b175474e89094c44da98b954eedeac495271d0f'},
{label:'SushiToken (SUSHI)', value:'0x6b3595068778dd592e39a122f4f5a5cf09c90fe2'},
{label:'HoloToken (HOT)', value:'0x6c6ee5e31d828de241282b9606c8e98ea48526e2'},
{label:'Wrapped Filecoin (WFIL)', value:'0x6e1A19F235bE7ED8E3369eF73b196C07257494DE'},
{label:'HuobiToken (HT)', value:'0x6f259637dcd74c767781e37bc6133cd6a68aa161'},
{label:'IoTeX Network (IOTX)', value:'0x6fb3e0a217407efff7ca062d46c26e5d60a14d69'},
{label:'StatusNetwork (SNT)', value:'0x744d70fdbe2ba4cf95131626614a1763df805b9e'},
{label:'OKB (OKB)', value:'0x75231f58b43240c9718dd58b4967c5114342a86c'},
{label:'HarmonyOne (ONE)', value:'0x799a4202c12ca952cb311598a024c80ed371a41e'},
{label:'Golem Network Token (GLM)', value:'0x7DD9c5Cba05E151C895FDe1CF355C9A1D5DA6429'},
{label:'EthLend (LEND)', value:'0x80fB784B7eD66730e8b1DBd9820aFD29931aab03'},
{label:'SushiBar (xSUSHI)', value:'0x8798249c2E607446EfB7Ad49eC89dD1865Ff4272'},
{label:'Swipe (SXP)', value:'0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9'},
{label:'Aave interest bearing CRV (aCRV)', value:'0x8dae6cb04688c62d939ed9b68d32bc62e49970b1'},
{label:'Paxos Standard (PAX)', value:'0x8e870d67f660d95d5be530380d0ec0bd388289e1'},
{label:'SingularityNET (AGI)', value:'0x8eb24319393716668d768dcec29356ae9cffe285'},
{label:'Fei USD (FEI)', value:'0x956F47F50A910163D8BF957Cf5846D573E7f87CA'},
{label:'Ocean Token (OCEAN)', value:'0x967da4048cD07aB37855c090aAF366e4ce1b9F48'},
{label:'Maker (MKR)', value:'0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2'},
{label:'Crypto.com Coin (CRO)', value:'0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b'},
{label:'AlphaToken (ALPHA)', value:'0xa1faa113cbe53436df28ff0aee54275c13b40975'},
{label:'Wrapped UST Token (UST)', value:'0xa47c8bf37f92abed4a126bda807a7b7498661acd'},
{label:'Celsius (CEL)', value:'0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d'},
{label:'stETH (stETH)', value:'0xae7ab96520de3a18e5e111b5eaab095312d7fe84'},
{label:'Fetch (FET)', value:'0xaea46A60368A7bD060eec7DF8CBa43b7EF41Ad85'},
{label:'FirstBlood (ST)', value:'0xaf30d2a7e90d7dc361c8c4585e9bb7d2f6f15bc7'},
{label:'Rocket Pool (RPL)', value:'0xb4efd85c19999d84251304bda99e90b92300bd93'},
{label:'Nexo (NEXO)', value:'0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206'},
{label:'Balancer (BAL)', value:'0xba100000625a3754423978a60c9317c58a424e3d'},
{label:'BandToken (BAND)', value:'0xba11d00c5f74255f56a5e366f4f77f5a186d7f55'},
{label:'SwissBorg (CHSB)', value:'0xba9d4199fab4f26efe3551d490e3821486f135ba'},
{label:'LoopringCoin V2 (LRC)', value:'0xbbbbca6a901c926f240b89eacb641d8aec7aeafd'},
{label:'StormX (STMX)', value:'0xbe9375c6a420d2eeb258962efb95551a5b722803'},
{label:'Compound (COMP)', value:'0xc00e94cb662c3520282e6f5717214004a7f26888'},
{label:'Synthetix Network Token (SNX)', value:'0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f'},
{label:'Tribe (TRIBE)', value:'0xc7283b66eb1eb5fb86327f08e1b5816b0720212b'},
{label:'Graph Token (GRT)', value:'0xc944e90c64b2c07662a292be6244bdf05cda44a7'},
{label:'Bytom (BTM)', value:'0xcb97e65f07da24d46bcdd078ebebd7c6e6e3d750'},
{label:'BitMax token (BTMX)', value:'0xcca0c9c383076649604eE31b20248BC04FdF61cA'},
{label:'OMG Network (OMG)', value:'0xd26114cd6EE289AccF82350c8d8487fedB8A0C07'},
{label:'NXM (NXM)', value:'0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b'},
{label:'KyberNetwork (KNC)', value:'0xdd974d5c2e2928dea5f71b9825b8b646686bd200'},
{label:'HUSD (HUSD)', value:'0xdf574c24545e5ffecb9a659c229253d4111d87e1'},
{label:'Injective Token (INJ)', value:'0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30'},
{label:'ZRX (ZRX)', value:'0xe41d2489571d322189246dafa5ebde1f4699f498'},
{label:'Wrapped Celo (wCELO)', value:'0xe452e6ea2ddeb012e20db73bf5d3863a3ac8d77a'},
{label:'renBTC (renBTC)', value:'0xeb4c2781e4eba804ce9a9803c67d0893436bb27d'},
{label:'KuCoin Token (KCS)', value:'0xf34960d9d60be18cc1d5afc1a6f012a723a28811'},
{label:'EnjinCoin (ENJ)', value:'0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c'},
{label:'Compound USDT (cUSDT)', value:'0xf650c3d88d12db855b8bf7d11be6c55a4e07dcc9'},
{label:'IOSToken (IOST)', value:'0xfa1a856cfa3409cfa145fa4e20eb270df3eb21ab'},
{label:'Token Prometeus Network (PROM)', value:'0xfc82bb4ba86045af6f327323a46e80412b91b27d'},
{label:'Wrapped Decentraland MANA (wMANA)', value:'0xfd09cf7cfffa9932e33668311c4777cb9db3c9be'},
{label:'Amp (AMP)', value:'0xff20817765cb7f73d4bde2e66e067e58d11095c2'},
{label:'LON Token (LON)', value:'0x0000000000095413afc295d19edeb1ad7b71c952'},
{label:'Gemini dollar (GUSD)', value:'0x056fd409e1d7a124bd7017459dfea2f387b6d5cd'},
{label:'LGO Token (LGO)', value:'0x0a50c93c762fdd6e56d86215c24aaad43ab629aa'},
{label:'STAKE (STAKE)', value:'0x0Ae055097C6d159879521C384F1D2123D1f195e6'},
{label:'Bifrost (BFC)', value:'0x0c7D5ae016f806603CB1782bEa29AC69471CAb9c'},
{label:'DATAcoin (DATA)', value:'0x0cf0ee63788a0849fe5297f3407f701e122cc023'},
{label:'Wrapped NXM (wNXM)', value:'0x0d438f3b5175bebc262bf23753c1e53d03432bde'},
{label:'CarryToken (CRE)', value:'0x115ec79f1de567ec68b7ae7eda501b406626478e'},
{label:'Armor (ARMOR)', value:'0x1337def16f9b486faed0293eb623dc8395dfe46a'},
{label:'Divi Exchange Token (DIVX)', value:'0x13f11c9905a08ca76e3e853be63d4f0944326c72'},
{label:'PAID Network (PAID)', value:'0x1614f18fc94f47967a3fbe5ffcd46d4e7da3d787'},
{label:'VesperToken (VSP)', value:'0x1b40183efb4dd766f11bda7a7c3ad8982e998421'},
{label:'ViteToken (VITE)', value:'0x1b793e49237758dbd8b752afc9eb4b329d5da016'},
{label:'Reputation (REPv2)', value:'0x221657776846890989a759ba2973e427dff5c9bb'},
{label:'Propy (PRO)', value:'0x226bb599a12c826476e3a771454697ea52e9e220'},
{label:'Crypto20 (C20)', value:'0x26e75307fc0c021472feb8f727839531f112f317'},
{label:'AirSwap (AST)', value:'0x27054b13b1b798b345b591a4d22e6562d47ea75a'},
{label:'OCC (OCC)', value:'0x2f109021afe75b949429fe30523ee7c0d5b27207'},
{label:'Free Coin (FREE)', value:'0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd'},
{label:'MANTRA DAO (OM)', value:'0x3593d125a4f7849a1b059e64f4517a86dd60c95d'},
{label:'Parsiq Token (PRQ)', value:'0x362bc847A3a9637d3af6624EeC853618a43ed7D2'},
{label:'Empty Set Dollar (ESD)', value:'0x36F3FD68E7325a35EB768F1AedaAe9EA0689d723'},
{label:'Yuan Chain New (YCC)', value:'0x37e1160184f7dd29f00b78c050bf13224780b0b0'},
{label:'DSLA (DSLA)', value:'0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe'},
{label:'Dragon (DRGN)', value:'0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e'},
{label:'dForce (DF)', value:'0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0'},
{label:'BZ (BZ)', value:'0x4375e7ad8a01b8ec3ed041399f62d9cd120e0063'},
{label:'Paxos Gold (PAXG)', value:'0x45804880De22913dAFE09f4980848ECE6EcbAf78'},
{label:'TrueFi (TRU)', value:'0x4c19596f5aaff459fa38b0f7ed92f11ae6543784'},
{label:'AION (AION)', value:'0x4CEdA7906a5Ed2179785Cd3A40A69ee8bc99C466'},
{label:'MCDEX Token (MCB)', value:'0x4e352cF164E64ADCBad318C3a1e222E9EBa4Ce42'},
{label:'WaykiCoin (WIC)', value:'0x4f878c0852722b0976a955d68b376e4cd4ae99e5'},
{label:'bZx Protocol Token (BZRX)', value:'0x56d811088235F11C8920698a204A5010a788f4b3'},
{label:'Bluzelle (BLZ)', value:'0x5732046a883704404f284ce41ffadd5b007fd668'},
{label:'Synth sUSD (sUSD)', value:'0x57ab1ec28d129707052df4df418d58a2d46d5f51'},
{label:'Marlin POND (POND)', value:'0x57b946008913b82e4df85f501cbaed910e58d26c'},
{label:'Hegic (HEGIC)', value:'0x584bC13c7D411c00c01A62e8019472dE68768430'},
{label:'PowerLedger (POWR)', value:'0x595832f8fc6bf59c85c527fec3740a1b7a361269'},
{label:'Lido DAO Token (LDO)', value:'0x5a98fcbea516cf06857215779fd812ca3bef1b32'},
{label:'MXCToken (MXC)', value:'0x5ca381bbfb58f0092df149bd3d243b08b9a8386e'},
{label:'LockTrip (LOC)', value:'0x5e3346444010135322268a4630d2ed5f8d09446c'},
{label:'AIOZ Network (AIOZ)', value:'0x626e8036deb333b408be468f951bdb42433cbf18'},
{label:'Mask Network (MASK)', value:'0x69af81e73a73b40adf4f3d4223cd9b1ece623074'},
{label:'Quickswap (QUICK)', value:'0x6c28AeF8977c9B773996d0e8376d2EE379446F2f'},
{label:'BOSAGORA (BOA)', value:'0x746dda2ea243400d5a63e0700f190ab79f06489e'},
{label:'Illuvium (ILV)', value:'0x767fe9edc9e0df98e07454847909b5e959d7ca0e'},
{label:'TORN Token (TORN)', value:'0x77777feddddffc19ff86db637967013e6c6a116c'},
{label:'Sentivate (SNTVT)', value:'0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8'},
{label:'Kin (KIN)', value:'0x818fc6c2ec5986bc6e2cbf00939d90556ab12ce5'},
{label:'OriginToken (OGN)', value:'0x8207c1ffc5b6804f6024322ccf34f29c3541ae26'},
{label:'UniBright (UBT)', value:'0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e'},
{label:'DIAToken (DIA)', value:'0x84cA8bc7997272c7CfB4D0Cd3D55cd942B3c9419'},
{label:'Frax (FRAX)', value:'0x853d955acef822db058eb8505911ed77f175b99e'},
{label:'KEEP Token (KEEP)', value:'0x85eee30c52b0b379b046fb0f85f4f3dc3009afec'},
{label:'Uquid Coin (UQC)', value:'0x8806926Ab68EB5a7b909DcAf6FdBe5d93271D6e2'},
{label:'Tellor Tributes (TRB)', value:'0x88df592f8eb5d7bd38bfef7deb0fbc02cf3778a0'},
{label:'Lambda (LAMB)', value:'0x8971f9fd7196e5cee2c1032b50f656855af7dd26'},
{label:'pNetwork Token (PNT)', value:'0x89Ab32156e46F46D02ade3FEcbe5Fc4243B9AAeD'},
{label:'Sai Stablecoin v1.0 (SAI)', value:'0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359'},
{label:'Chroma (CHR)', value:'0x8A2279d4A90B6fe1C4B30fa660cC9f926797bAA2'},
{label:'Guaranteed Entrance Token (GET)', value:'0x8a854288a5976036a725879164ca3e91d30c6a1b'},
{label:'Function X (FX)', value:'0x8c15ef5b4b21951d50e53e4fbda8298ffad25057'},
{label:'Request (REQ)', value:'0x8f8221afbb33998d8584a2b05749ba73c37a938a'},
{label:'Aergo (AERGO)', value:'0x91Af0fBB28ABA7E31403Cb457106Ce79397FD4E6'},
{label:'Pinakion (PNK)', value:'0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d'},
{label:'Dusk Network (DUSK)', value:'0x940a2db1b7008b6c776d4faaca729d6d4a4aa551'},
{label:'SONM (SNM)', value:'0x983f6d60db79ea8ca4eb9968c6aff8cfa04b3c63'},
{label:'Shopping.io (SPI)', value:'0x9b02dd390a603add5c07f9fd9175b7dabe8d63b7'},
{label:'Aragon Network Token (ANT)', value:'0xa117000000f279d81a1d3cc75430faa017fa5a2e'},
{label:'Nuls (NULS)', value:'0xa2791bdf2d5055cda4d46ec17f9f429568275047'},
{label:'Metronome (MET)', value:'0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e'},
{label:'ETH 2x Flexible Leverage Index (ETH2x-FLI)', value:'0xaa6e8127831c9de45ae56bb1b0d4d4da6e5665bd'},
{label:'Trace (TRAC)', value:'0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f'},
{label:'ALICE (ALICE)', value:'0xac51066d7bec65dc4589368da368b212745d63e8'},
{label:'Litentry (LIT)', value:'0xb59490ab09a0f526cc7305822ac65f2ab12f9723'},
{label:'Storj (STORJ)', value:'0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac'},
{label:'BTU Protocol (BTU)', value:'0xb683d83a532e2cb7dfa5275eed3698436371cc9f'},
{label:'Spice (SFI)', value:'0xb753428af26e81097e7fd17f40c88aaa3e04902c'},
{label:'ZBToken (ZB)', value:'0xbd0793332e9fb844a52a205a233ef27a5b34b927'},
{label:'ELF (ELF)', value:'0xbf2179859fc6d5bee9bf9158632dc51678a4100e'},
{label:'Cream ETH 2 (CRETH2)', value:'0xcBc1065255cBc3aB41a6868c22d1f1C573AB89fd'},
{label:'Aurora DAO (AURA)', value:'0xcdcfc0f66c522fd086a1b725ea3c0eeb9f9e8814'},
{label:'BetProtocolToken (BEPRO)', value:'0xcf3c8be2e2c42331da80ef210e9b1b307c03d36a'},
{label:'NimiqNetwork (NET)', value:'0xcfb98637bcae43C13323EAa1731cED2B716962fD'},
{label:'Fusion (FSN)', value:'0xd0352a019e9ab9d757776f532377aaebd36fd541'},
{label:'Refereum (RFR)', value:'0xd0929d411954c47438dc1d871dd6081f5c5e149c'},
{label:'Rari Governance Token (RGT)', value:'0xD291E7a03283640FDc51b121aC401383A46cC623'},
{label:'Cindicator (CND)', value:'0xd4c435f5b09f855c3317c8524cb1f586e42795fa'},
{label:'Populous (PPT)', value:'0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a'},
{label:'STPT (STPT)', value:'0xde7d85157d9714eadf595045cc12ca4a5f3e2adb'},
{label:'Mainframe Token (MFT)', value:'0xdf2c7238198ad8b389666574f2d8bc411a4b7428'},
{label:'Somnium Space Cubes (CUBE)', value:'0xdf801468a808a32656d2ed2d2d80b72a129739f4'},
{label:'DGD (DGD)', value:'0xe0b7927c4af23765cb51314a0e0521a9645f0e2a'},
{label:'ZEON (ZEON)', value:'0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532'},
{label:'QuarkChain Token (QKC)', value:'0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664'},
{label:'Melon Token (MLN)', value:'0xec67005c4e498ec7f55e092bd1d35cbc47c91892'},
{label:'Metal (MTL)', value:'0xF433089366899D83a9f26A773D59ec7eCF30355e'},
{label:'Frontier Token (FRONT)', value:'0xf8C3527CC04340b208C854E985240c02F7B7793f'},
{label:'RipioCreditNetwork (RCN)', value:'0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6'},
{label:'Rarible (RARI)', value:'0xfca59cd816ab1ead66534d82bc21e7515ce441cf'},
]

export default addresses;
