const { request } = require("@octokit/request");

let autorizedRequest;
const STORAGE_KEY = 'MARKS_GITHUB_TOKEN';

async function init () {
    const extensions = {
        label: 'github',
        items: [
            {
                label: 'login-token',
                value: githubLoginToken,
            },
            {
                label: 'login-token-get',
                value: githubLoginTokenGet,
            },
            {
                label: 'login-auth',
                value: githubLoginAuth,
            },
            {
                label: 'request',
                value: githubRequest,
            },
            {
                label: 'request-raw',
                value: githubRequestRaw,
            },
        ]
    }
    return {extensions};
}

export default init;

function githubLoginTokenGet() {
    return localStorage.getItem(STORAGE_KEY);
}

async function githubLoginToken (token) {
    if (!token) {
        token = localStorage.getItem(STORAGE_KEY);
    }
    else {
        localStorage.setItem(STORAGE_KEY, token);
    }
    autorizedRequest = request.defaults({
        headers: {
          authorization: `token ${token}`,
        },
    });
    let userData;
    try {
        userData = await autorizedRequest('GET /user');
    } catch (e) {return null;}
    return userData.data;
}

function githubLoginAuth () {

}

function githubRequestRaw (request, data) {
    console.log('githubRequest', request);
    // const result = await requestWithAuth("GET /user");
    return autorizedRequest(request, data).catch(e => e.message);
}

async function githubRequest (request, data) {
    const result = await githubRequestRaw(request, data).catch(e => {
        console.error(e);
        return e.message
    });
    if (result && typeof result === 'object') return result.data;
    return result;
}
