import React, {useState, useRef, useMemo, useCallback} from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import './map.css';
import {MAPBOX_TOKEN} from '../../config';

import { icon } from "leaflet"

const DEFAULT_CENTER = [41.505, 5.19];
const DEFAULT_ZOOM = 13;
const ICON = icon({
  iconUrl: "/menu/poi.svg",
  iconSize: [20, 25], // see .leaflet-marker-icon css
})
const TileDefault = (
    <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
)

const TileMapBox = (
    <TileLayer
        attribution='© <a href="https://www.mapbox.com/about/maps/">Mapbox</a> © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>'
        url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
        id='mapbox/dark-v10'
        accessToken={MAPBOX_TOKEN}
    />
)

const TileStadia = (
    <TileLayer
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
    />
)

function safeCoords(coords) {
    const _coords = coords instanceof Array ? coords : [coords.lat, coords.lng];
    // NaN check
    if (!_coords[0]) _coords[0] = 0;
    if (!_coords[1]) _coords[1] = 0;
    let lat = Math.min(90, _coords[0]);
    lat = Math.max(-90, lat);
    let lng = Math.min(180, _coords[1]);
    lng = Math.max(-180, lng);
    return [lat, lng];
}


function LocationMarker(props) {
    const {center, onChange} = props;
    const [position, setPosition] = useState(center);

    const map = useMapEvents({
        dragstart (e) {
            setPosition(map.getCenter())
        },
        drag (e) {
            setPosition(map.getCenter())
        },
        dragend (e) {
            const pos = map.getCenter();
            setPosition(pos);
            if (onChange) onChange(pos);
        },
    });

    React.useEffect(() => {
        if (!center) return;
        setPosition(center);
        map.flyTo(center);
    }, [center, map]);

    return (
        <Marker icon={ICON} position={safeCoords(position)}></Marker>
    )
}

function SimpleMarker(props) {
    const {coordinates, link, label, info} = props;
    const _link = typeof link === 'string' ? link : '#';
    const [popupLink, setLink] = React.useState(_link);
    const [linkLabel, setLinkLabel] =  React.useState('');

    const eventHandlers = {
        click: async () => {
            if (typeof link === 'function') {
                const _link = await link();
                if (typeof _link === 'string') {
                    setLink(_link);
                    setLinkLabel('go to submission');
                }
            }
        }
    }

    return (
        <Marker icon={ICON} position={safeCoords(coordinates)} eventHandlers={eventHandlers}>
            <Popup>
                <p>{label}</p>
                {popupLink ? <a href={popupLink} target="_blank">{linkLabel}</a> : <span>loading link...</span>}
                <div style={{maxWidth: '300px', maxHeight: '100px', overflowY: 'auto'}}>
                    {info ? <p>{info}</p> : <span></span>}
                </div>
            </Popup>
        </Marker>
    )
}

function Map (props) {
    let {center, zoom, onChange} = props;
    center = center || [...DEFAULT_CENTER];
    zoom = zoom || DEFAULT_ZOOM;

    center = safeCoords(center);

    return (
        <MapContainer {...props} center={center} zoom={zoom} scrollWheelZoom={false}>
            {TileMapBox}
            <LocationMarker center={center} onChange={onChange}/>
        </MapContainer>
    )
}


export function MapView (props) {
    let {center, zoom, values=[]} = props;
    center = center || [...DEFAULT_CENTER];
    zoom = zoom || DEFAULT_ZOOM;
    const _values = values instanceof Array ? values : [];

    const placemarks = _values.map((val, i) => {
        return <SimpleMarker key={i} {...val} />
    });

    center = safeCoords(center);

    return (
        <MapContainer {...props} center={center} zoom={zoom} scrollWheelZoom={false}>
            {TileMapBox}
            {placemarks}
        </MapContainer>
    )
}
export default Map;
