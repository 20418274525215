export const clipboardCopy = (text) => {
    const aux = document.createElement("input");
    aux.setAttribute("value", text);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
};

export const isUndefined = val => typeof val === 'undefined';
export const isNull = val => val === null;

export const safeChoice = (...args) => {
    return args.reduce((accum, val) => {
        const noAccum = isUndefined(accum) || isNull(accum);
        return noAccum ? val : accum;
    }, null);
}

export function waitFor (operation, interval) {
    return new Promise(async (resolve, reject) => {
        let resolved = false;
        const timerId = setTimeout(() => {
            if (!resolved) {
                resolved = true;
                resolve();
            }
        }, interval);
        const result = await operation();
        if (!resolved) {
            clearTimeout(timerId);
            resolved = true;
            resolve(result);
        }
    });
}

export function makeSync (operation, waitTime) {
    return operation;
}

export function getAllFuncs(toCheck) {
    const props = [];
    toCheck = toCheck || {};
    let obj = toCheck;
    do {
        props.push(...Object.getOwnPropertyNames(obj));
    } while (obj = Object.getPrototypeOf(obj));

    return props.sort().filter((e, i, arr) => {
       if (e!=arr[i+1] && typeof toCheck[e] == 'function') return true;
    });
}
